import FilterGroup from 'components/filter-group/filter-group';
import {
  MAPDFilters,
  SNPFilters
} from 'components/filter-group/filter-group-data';
import { useEffect, useState } from 'react';

const PlanListFilters = ({ version }) => {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setFilters(version === 'SNP' ? SNPFilters : MAPDFilters);
  }, [version]);

  return (
    <div>
      <div className="heading-5 text-blue-primary mb-10">
        Filter Your Results
      </div>
      <div className="mb-20">
        Selecting a filter will change results immediately.
      </div>
      {filters.map((filterGroup, index) => (
        <div className="border-top mb-20 pt-15" key={index}>
          <FilterGroup
            contentPrompt={filterGroup.contentPrompt}
            header={<div className="text-bold">{filterGroup.name}</div>}
            id={`filter-group-${index}`}
            defaultExpanded={false}
            filters={filterGroup.filters}
          >
            {filterGroup.content}
          </FilterGroup>
        </div>
      ))}
    </div>
  );
};

export default PlanListFilters;
