import React from 'react';
import './flyout-panel.scss';

const FlyoutPanel = (props) => {
  const { children, ...rest } = props;
  return (
    <div className="uhc-flyout-panel" {...rest}>
      <div className="uhc-flyout-panel__inner">{children}</div>
    </div>
  );
};

export default FlyoutPanel;
