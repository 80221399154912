import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './tab-navigation.scss';

const TabNavigation = (props) => {
  const { className, tabs, ...rest } = props;
  const componentClassName = classNames('uhc-tab-navigation', {}, className);

  return (
    <div className={componentClassName} {...rest}>
      <nav className="uhc-tab-navigation__nav" aria-label="Tabs">
        {tabs.map((tab, index) => (
          <Link
            key={index}
            to={tab.href}
            className={classNames(
              'uhc-tab-navigation__tab',
              tab.current ? 'uhc-tab-navigation__tab--current' : ''
            )}
            aria-current={tab.current ? 'page' : undefined}
          >
            {tab.name}
          </Link>
        ))}
      </nav>
    </div>
  );
};

TabNavigation.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      href: PropTypes.string,
      current: PropTypes.bool
    })
  )
};

TabNavigation.defaultProps = {
  tabs: []
};

export default TabNavigation;
