import { LinkButton } from '@bswing/uhc-core';
import PrintAndEmail from 'components/print-and-email/print-and-email';
import { Link } from 'react-router-dom';
import MSPlanDetailActions from '../ms-plan-detail-actions/ms-plan-detail-actions';

const MSPlanDetailHeader = (props) => {
  const {
    data,
    isSaved,
    showCompare,
    showInformationAdded,
    onPlanSaveToggle = () => {}
  } = props;

  return (
    <div className="bg-teal-quaternary pt-20 pt-lg-30 pb-30">
      <div className="container container-lg">
        <div className="row mb-20 mb-lg-40">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
              <LinkButton
                as={Link}
                to="/health-plans/plan-summary/medicare-supplement-plans"
                className="mb-20 mb-lg-0"
              >
                <img
                  className="mr-10"
                  src="/assets/icons/chevron-left-icon@2x.png"
                  alt=""
                  style={{ height: 12, width: 8, verticalAlign: -1 }}
                />
                View All Medicare Supplement Plans (10)
              </LinkButton>
              <div className="d-flex flex-column flex-lg-row items-center">
                <div className="d-flex items-center mr-0 mr-lg-30 mb-20 mb-lg-0">
                  <img
                    className="mr-10"
                    src="/assets/icons/map-pin@2x.png"
                    alt=""
                    style={{ height: 21, width: 21 }}
                  />
                  <span>
                    Plans for{' '}
                    <LinkButton>
                      <span className="d-flex align-items-center gap-10">
                        <span>59102 Billings Yellowstone County</span>
                        <img
                          src="/assets/icons/pencil-copy@2x.png"
                          alt=""
                          style={{ height: 12, width: 12 }}
                        />
                      </span>
                    </LinkButton>
                  </span>
                </div>
                <div className="d-none d-lg-block">
                  <PrintAndEmail
                    emailLabel="Email plan details"
                    printLabel="Print plan details"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-20 mb-lg-30">
          <div className="column column-12">
            <h1 className="display-large mb-10">{data.name}</h1>
            <div className="heading-6 text-blue-primary">
              {data.description}
            </div>
          </div>
        </div>
        <div className="row mb-30 mb-lg-50">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row align-items-lg-start">
              {data.monthlyPremium && (
                <div>
                  <div className="text-large">Monthly Premium starting at</div>
                  <div className="text-bold display-large text-dark">
                    <span
                      dangerouslySetInnerHTML={{ __html: data.monthlyPremium }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mt-15">
              <LinkButton className="text-lg-large">
                <span className="d-flex align-items-center gap-5">
                  <img
                    className="d-block"
                    src="/assets/icons/add-box@2x.png"
                    alt=""
                    style={{ height: 24, width: 25 }}
                  />
                  <span>
                    {showInformationAdded
                      ? 'Edit your information to update the estimated premium'
                      : 'Add your information to update the estimated premium'}
                  </span>
                </span>
              </LinkButton>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column column-12 column-lg-6">
            <MSPlanDetailActions
              isSaved={isSaved}
              showCompare={showCompare}
              onPlanSaveToggle={onPlanSaveToggle}
            />
            <div className="text-small mt-30">
              AARP® Medicare Supplement Insurance Plan insured by
              UnitedHealthcare Insurance Company
            </div>
            <div className="d-block d-lg-none mt-30">
              <PrintAndEmail
                emailLabel="Email plan details"
                printLabel="Print plan details"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSPlanDetailHeader;
