import { Button } from '@bswing/uhc-core';
import { Fragment } from 'react';

const MSPlanDetailStickyHeader = ({
  data,
  isSaved,
  onPlanSaveToggle = () => {}
}) => {
  return (
    <div
      className="uhc-sticky bg-teal-quaternary fixed-top py-15 py-lg-20"
      aria-hidden="true"
      style={{ boxShadow: '0 2px 4px 0 rgb(0 0 0 / 25%)', zIndex: 10 }}
    >
      <div className="container container-lg">
        <div className="row">
          <div className="column column-12">
            <div className="d-flex align-items-center justify-content-between flex-wrap gap-20">
              <div>
                <div className="heading-3">{data.name}</div>
                {data.monthlyPremium && (
                  <div className="text-bold mt-5">
                    Monthly Premium: {data.monthlyPremiumAlt}
                  </div>
                )}
              </div>
              <div className="d-none d-lg-flex align-items-center">
                <div>
                  <span className="text-bold text-blue-primary">
                    Need Help? Call 1-866-462-4046
                  </span>{' '}
                  <span>(TTY 711)</span>
                </div>
                <Button className="ml-20">Start Application</Button>
                <Button
                  className="ml-10"
                  isOutlined={true}
                  isSecondary={true}
                  onClick={onPlanSaveToggle}
                >
                  <span className="d-flex align-items-center">
                    {isSaved && (
                      <Fragment>
                        <img
                          className="d-block mr-10 ml-n5"
                          src="/assets/icons/heart-icon-small-filled@2x.png"
                          alt=""
                          style={{
                            height: 16,
                            width: 16
                          }}
                        />
                        <span>Saved</span>
                      </Fragment>
                    )}
                    {!isSaved && (
                      <Fragment>
                        <img
                          className="d-block mr-10 ml-n5"
                          src="/assets/icons/heart-icon-small@2x.png"
                          alt=""
                          style={{
                            height: 16,
                            width: 16
                          }}
                        />
                        <span>Save</span>
                      </Fragment>
                    )}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSPlanDetailStickyHeader;
