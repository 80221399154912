import { Button, Checkbox, LinkButton, Popup } from '@bswing/uhc-core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import './ms-plan-card.scss';

const MSPlanCard = (props) => {
  const {
    compareCount,
    className,
    isExpanded: isDefaultExpanded,
    isSaved,
    planData,
    showCompare,
    showCompareCheckbox,
    showDiscounts,
    showNLE,
    showRestrictions,
    showRemove,
    onCompare,
    onRemove,
    onSave,
    ...rest
  } = props;

  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);

  const componentClassName = classNames(
    'uhc-ms-plan-card',
    {
      'uhc-ms-plan-card--expanded': isExpanded
    },
    className
  );

  const handleOnCompareChange = (value, planName) => {
    return (event) => {
      onCompare(event, value, planName);
    };
  };

  const handleOnRemove = (planName) => {
    return (event) => {
      onRemove(event, planName);
    };
  };

  const handleOnSave = (value, planName) => {
    return (event) => {
      onSave(event, value, planName);
    };
  };

  return (
    <div className={componentClassName} {...rest}>
      <div className="uhc-ms-plan-card__header">
        <div className="d-flex align-items-center justify-content-between mb-5">
          <div className="text-small">Medicare Supplement Plan</div>
          <div className="d-flex align-items-center gap-20">
            {showRemove && (
              <div>
                <LinkButton
                  className="text-white text-small"
                  onClick={handleOnRemove(planData?.name)}
                >
                  <span className="d-flex align-items-center gap-10">
                    <span className="d-none d-lg-block">Remove</span>
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden={true}
                      focusable={false}
                    >
                      <path
                        d="M12.75 13.25L4.25 4.75"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.75 4.75L4.25 13.25"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </LinkButton>
              </div>
            )}
          </div>
        </div>
        <div>
          <h2 className="heading-2 text-white mb-5 mb-lg-0">
            {planData?.name}
          </h2>
          <div className="text-bold text-white">{planData?.description}</div>
        </div>
      </div>
      <div className="uhc-ms-plan-card__main">
        {showRestrictions && (
          <div className="row row-collapse mb-20">
            <div className="column column-12">
              <h3 className="bg-teal-quaternary text-sans-serif text-normal text-gray-8 text-bold p-15">
                {planData?.name} is only available to those first eligible for
                Medicare before 2020.
              </h3>
            </div>
          </div>
        )}

        <div className="row row-collapse mb-20">
          <div className="column column-12 column-lg-4">
            {planData.monthlyPremium && (
              <div className="mb-20">
                <div>Monthly premium starting at:</div>
                <div className="heading-2 text-dark mb-20">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: planData.monthlyPremium
                    }}
                  />
                </div>
                <div className="mb-20">
                  Add your information to update the estimated monthly premium.
                </div>
                <div>
                  <LinkButton className="text-small">
                    <span className="d-flex align-items-center gap-10">
                      <img
                        src="/assets/icons/full-icon-library-all-24-px-content-ic-add-box@2x.png"
                        alt=""
                        style={{ width: 17, height: 17 }}
                      />
                      <span>Add your information</span>
                    </span>
                  </LinkButton>
                </div>
              </div>
            )}
          </div>
          <div className="column column-12 column-lg-8">
            <div className="d-flex align-items-center justify-content-between text-bold mb-15">
              <h4 className="text-sans-serif text-normal text-gray-8 text-bold">
                Plan Information
              </h4>
              <div>You Pay</div>
            </div>
            <div className="border border-rounded-sm">
              <table>
                <caption className="visually-hidden">
                  Plan Card Table Caption
                </caption>
                <tbody>
                  {/* START FROM DATA */}
                  {planData?.planCard?.lineItems &&
                    planData?.planCard?.lineItems.map((lineItem, index) => (
                      <tr
                        className={`d-flex justify-content-between gap-20 p-10 ${
                          index > 0 ? 'border-top' : ''
                        }`}
                        key={index}
                      >
                        <td className="p-0 border-none">
                          <div>
                            <div className="d-flex align-items-center gap-5">
                              {lineItem.label}
                              {index === 0 && (
                                <Popup
                                  id="doctor-visits-popup"
                                  animate={true}
                                  position="top"
                                  text={
                                    <div>
                                      The pre-set, fixed amount you must pay for
                                      health care costs before the insurance
                                      company or Medicare begins to pay. Please
                                      see you Evidence of Coverage for details.
                                    </div>
                                  }
                                >
                                  <LinkButton className="d-block">
                                    <img
                                      className="d-block"
                                      src="/assets/icons/info-icon@2x.png"
                                      alt=""
                                      style={{ height: 24, width: 24 }}
                                    />
                                  </LinkButton>
                                </Popup>
                              )}
                            </div>
                            {lineItem.additional && (
                              <div className="text-small mt-5">
                                {lineItem.additional}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="p-0 border-none text-bold text-right">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: lineItem.value
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {showDiscounts && (
              <div className="mt-20">
                <div className="d-flex flex-wrap align-items-end gap-10 text-bold mb-15">
                  <h4 className="text-sans-serif text-normal text-gray-8 text-bold">
                    <span>
                      Wellness extras
                      <sup>
                        <a
                          className="text-bold no-underline"
                          href="#footnote-10"
                        >
                          10
                        </a>
                      </sup>
                    </span>
                  </h4>{' '}
                  <LinkButton className="text-small">Learn more</LinkButton>
                </div>
                <ul className="uhc-ms-plan-card__benefits">
                  {planData?.planCard?.benefits &&
                    planData?.planCard?.benefits.map((benefit, index) => (
                      <li className="d-flex align-items-start" key={index}>
                        <div className="mr-10">
                          <img
                            src="/assets/icons/check-icon-alt@2x.png"
                            alt=""
                            style={{ width: 17, height: 11 }}
                          />
                        </div>
                        <div>{benefit}</div>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="uhc-ms-plan-card__footer">
        <button
          className="uhc-plan-card__expand-button"
          onClick={() => setIsExpanded(!isExpanded)}
          aria-hidden={true}
          tabIndex={-1}
        >
          {isExpanded ? 'Show less' : 'Show more'}
          <img
            className="ml-10"
            src="/assets/icons/chevron-down-icon@2x.png"
            alt=""
            style={{
              width: 12,
              height: 8,
              transform: isExpanded ? 'scaleY(-1)' : 'none'
            }}
          />
        </button>
        <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-20">
          <Button className="d-block width-100 width-lg-auto mt-20 mt-lg-0">
            Start application
          </Button>
          <div className="d-flex align-items-center no-wrap">
            {showCompareCheckbox && (
              <Checkbox
                checked={showCompare}
                label={
                  showCompare ? (
                    <span className="visually-hidden">Add to compare</span>
                  ) : (
                    'Add to compare'
                  )
                }
                onChange={handleOnCompareChange(!showCompare, planData?.name)}
              />
            )}
            {showCompare && (
              <LinkButton>
                <span className="d-flex align-items-center">
                  <span>Compare Plans</span>
                  <img
                    className="ml-10"
                    src="/assets/icons/chevron-right-icon@2x.png"
                    alt=""
                    style={{ width: 8, height: 12 }}
                  />
                </span>
              </LinkButton>
            )}
          </div>
          <div className="d-flex flex-column flex-lg-row align-items-lg-center width-100">
            <div className="d-flex align-items-center justify-content-between width-100 gap-20">
              <span className="d-none d-lg-block flex"></span>
              <LinkButton
                as={Link}
                to={`/health-plans/details/${planData?.id}`}
              >
                <span className="d-flex align-items-center gap-5">
                  View plan details
                  <img
                    src="/assets/icons/chevron-right-icon@2x.png"
                    alt=""
                    style={{ width: 8, height: 12 }}
                  />
                </span>
              </LinkButton>
              <Button
                isOutlined={true}
                isSecondary={true}
                onClick={handleOnSave(!isSaved, planData)}
              >
                <span className="d-flex align-items-center">
                  {isSaved && (
                    <Fragment>
                      <img
                        className="d-block mr-10 ml-n5"
                        src="/assets/icons/heart-icon-small-filled@2x.png"
                        alt=""
                        style={{
                          height: 16,
                          width: 16
                        }}
                      />
                      <span>Saved</span>
                    </Fragment>
                  )}
                  {!isSaved && (
                    <Fragment>
                      <img
                        className="d-block mr-10 ml-n5"
                        src="/assets/icons/heart-icon-small@2x.png"
                        alt=""
                        style={{
                          height: 16,
                          width: 16
                        }}
                      />
                      <span>Save</span>
                    </Fragment>
                  )}
                </span>
              </Button>
            </div>
          </div>
        </div>
        <div className="uhc-ms-plan-card__legal">
          UnitedHealthcare Insurance Company{showNLE && ' of America'}{' '}
          (UnitedHealthcare)
        </div>
      </div>
    </div>
  );
};

MSPlanCard.propTypes = {
  className: PropTypes.string,
  compareCount: PropTypes.number,
  isExpanded: PropTypes.bool,
  isSaved: PropTypes.bool,
  planData: PropTypes.object,
  showCompare: PropTypes.bool,
  showCompareCheckbox: PropTypes.bool,
  showDiscounts: PropTypes.bool,
  showNLE: PropTypes.bool,
  showRemove: PropTypes.bool,
  showRestrictions: PropTypes.bool,
  onCompare: PropTypes.func,
  onRemove: PropTypes.func,
  onSave: PropTypes.func
};

MSPlanCard.defaultProps = {
  isExpanded: false,
  isSaved: false,
  planData: {},
  showCompare: false,
  showCompareCheckbox: true,
  showDiscounts: true,
  showNLE: false,
  showRemove: false,
  showRestrictions: true,
  onCompare: () => {},
  onRemove: () => {},
  onSave: () => {}
};

export default MSPlanCard;
