import PropTypes from 'prop-types';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './slide-over.scss';

const SlideOver = (props) => {
  const { children, footer, isShown, title } = props;
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      in={isShown}
      nodeRef={nodeRef}
      timeout={300}
      className="uhc-slide-over"
      classNames="uhc-slide-over"
      unmountOnExit
    >
      <div ref={nodeRef}>
        <div className="uhc-overlay uhc-overlay--centered uhc-overlay--dark">
          <div
            className="uhc-slide-over__panel"
            role="dialog"
            aria-label={title}
          >
            <div className="uhc-slide-over__content">{children}</div>
            {footer && <div className="uhc-slide-over__footer">{footer}</div>}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

SlideOver.propTypes = {
  footer: PropTypes.node,
  isShown: PropTypes.bool,
  title: PropTypes.string
};

SlideOver.defaultProps = {
  isShown: false
};

export default SlideOver;
