import { Accordion, Toggle } from '@bswing/uhc-core';
import { useState } from 'react';
import MSPlanDetailActions from '../ms-plan-detail-actions/ms-plan-detail-actions';
import CoverageDentistsTable from './coverage-dentists-table';
import CoverageDoctorsTable from './coverage-doctors-table';
import CoverageDrugsTable from './coverage-drugs-table';
import CoverageRiders from './coverage-riders';

const IMPORTANT_DOCUMENTS = [
  'Your Guide to AARP Medicare Supplement and Medicare Select Insurance Plans',
  'Guide to Health Insurance for People with Medicare',
  'Plan Overview',
  'Rate Pages',
  'Rules and Disclosures',
  'Benefit Table',
  'Enrollment Discount'
];

const MSPlanDetailCoverage = (props) => {
  const {
    data,
    isSaved,
    savedDoctors,
    showCompare,
    showDentists,
    showDoctors,
    showDrugs,
    showDrugPricingUnavailable,
    onAddDoctorsClick,
    onPlanSaveToggle = () => {}
  } = props;
  const [sectionToggles, setSectionToggles] = useState(
    data.coverageDetails.sections.map(() => 0)
  );

  const handleToggleChange = (event, index) => {
    let newArr = [...sectionToggles];
    newArr[index] = event;
    setSectionToggles(newArr);
  };

  return (
    <div className="container container-lg mb-40">
      <div className="row">
        <div className="column column-12">
          <h2 className="heading-2 mb-10 text-dark">Coverage Details</h2>
          <div className="text-normal text-lg-large border-bottom pb-20">
            {data.coverageDetails.description}
          </div>
          {data.coverageDetails.sections.map((section, index) => (
            <div className="border-bottom" key={index}>
              <Accordion
                id={`section-${index}`}
                arrowColorOverride="#196ecf"
                isExpanded={index === 0}
                isMinimal={true}
                header={
                  <div>
                    <h3
                      className="heading-5 text-blue-primary"
                      dangerouslySetInnerHTML={{
                        __html: section.name
                      }}
                    />
                    {section.description && (
                      <div className="mt-5">{section.description}</div>
                    )}
                  </div>
                }
              >
                <div className="d-flex flex-column gap-20 pb-20">
                  {section.showDentists && (
                    <CoverageDentistsTable showDentists={showDentists} />
                  )}
                  {section.showDoctors && (
                    <CoverageDoctorsTable
                      providerData={savedDoctors}
                      showDoctors={showDoctors}
                      onAddDoctorsClick={onAddDoctorsClick}
                    />
                  )}
                  {section.showDrugs && (
                    <CoverageDrugsTable
                      showDrugs={showDrugs}
                      showDrugPricingUnavailable={showDrugPricingUnavailable}
                    />
                  )}
                  {section.showNetworkToggle && (
                    <div className="d-block d-lg-none">
                      <Toggle
                        label="Network Toggle"
                        onChange={(event) => handleToggleChange(event, index)}
                        options={['In-network', 'Out-of-network']}
                      />
                    </div>
                  )}
                  {section.tables &&
                    section.tables.map((table, tableIndex) => (
                      <div key={tableIndex}>
                        {table.title && (
                          <div className="text-bold text-lg-large mb-10">
                            {table.title}
                          </div>
                        )}
                        <div className="border border-rounded-sm overflow-hidden">
                          <table style={{ tableLayout: 'fixed' }}>
                            <caption className="visually-hidden">
                              {section.name}
                            </caption>
                            {table.headers && (
                              <thead>
                                <tr className="bg-gray-2">
                                  {table.headers.map((header, headerIndex) => (
                                    <th
                                      className={`text-bold text-blue-primary p-20 ${
                                        headerIndex > 0
                                          ? 'd-none d-lg-table-cell'
                                          : ''
                                      }`}
                                      key={headerIndex}
                                      style={{
                                        width:
                                          headerIndex === 0 ? '35%' : 'auto'
                                      }}
                                    >
                                      {header}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                            )}
                            <tbody>
                              {table.rows.map((row, rowIndex) => (
                                <tr
                                  className={
                                    rowIndex % 2
                                      ? 'bg-gray-2 valign-top'
                                      : ' valign-top'
                                  }
                                  key={rowIndex}
                                >
                                  {row.map((rowData, rowDataIndex) => (
                                    <td
                                      className={`p-20 text-lg-large ${
                                        rowDataIndex > 0
                                          ? 'd-none d-lg-table-cell'
                                          : ''
                                      }`}
                                      colSpan={
                                        rowData.colSpan ? rowData.colSpan : 1
                                      }
                                      key={rowDataIndex}
                                      style={{
                                        width:
                                          rowDataIndex === 0 ? '35%' : 'auto'
                                      }}
                                    >
                                      <span
                                        className={
                                          rowDataIndex === 0 ? 'text-bold' : ''
                                        }
                                        dangerouslySetInnerHTML={{
                                          __html: rowData.value
                                        }}
                                      />
                                      <span className="d-block d-lg-none mt-10">
                                        {row[
                                          rowDataIndex +
                                            1 +
                                            (row[rowDataIndex + 1] &&
                                            row[rowDataIndex + 1].colSpan
                                              ? 0
                                              : sectionToggles[index])
                                        ] &&
                                          row[
                                            rowDataIndex +
                                              1 +
                                              (row[rowDataIndex + 1] &&
                                              row[rowDataIndex + 1].colSpan
                                                ? 0
                                                : sectionToggles[index])
                                          ].prefix &&
                                          `${
                                            row[
                                              rowDataIndex +
                                                1 +
                                                (row[rowDataIndex + 1] &&
                                                row[rowDataIndex + 1].colSpan
                                                  ? 0
                                                  : sectionToggles[index])
                                            ].prefix
                                          }: `}
                                        {row[
                                          rowDataIndex +
                                            1 +
                                            (row[rowDataIndex + 1] &&
                                            row[rowDataIndex + 1].colSpan
                                              ? 0
                                              : sectionToggles[index])
                                        ] && (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                row[
                                                  rowDataIndex +
                                                    1 +
                                                    (row[rowDataIndex + 1] &&
                                                    row[rowDataIndex + 1]
                                                      .colSpan
                                                      ? 0
                                                      : sectionToggles[index])
                                                ].value
                                            }}
                                          />
                                        )}
                                      </span>
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  {section.showRiders && <CoverageRiders />}
                  {section.showCTA && (
                    <MSPlanDetailActions
                      isSaved={isSaved}
                      showCompare={showCompare}
                      onPlanSaveToggle={onPlanSaveToggle}
                    />
                  )}
                </div>
              </Accordion>
            </div>
          ))}
          <div className="border-bottom">
            <Accordion
              id="section-important-documents"
              arrowColorOverride="#196ecf"
              isMinimal={true}
              header={
                <div>
                  <div className="heading-5 text-blue-primary">
                    Important Documents
                  </div>
                  <div className="mt-5">
                    Valuable resources that provide important information about
                    the coverage and benefits of the plan as well as other
                    documents that you may find useful.
                  </div>
                </div>
              }
            >
              <div className="d-flex flex-column gap-20 mb-20">
                {IMPORTANT_DOCUMENTS.map((document, index) => (
                  <div key={index}>
                    <a
                      className="uhc-link-button text-lg-large"
                      href="#link"
                      tabIndex="0"
                    >
                      <span className="d-flex align-items-center">
                        <img
                          className="mr-10"
                          src="/assets/icons/pdf-icon@2x.png"
                          alt=""
                          style={{ width: 14, height: 16 }}
                        />
                        <span>{document}</span>
                        <img
                          className="ml-10"
                          src="/assets/icons/external-blue-icon@2x.png"
                          alt="external icon"
                          width="14"
                          height="14"
                        />
                      </span>
                    </a>
                  </div>
                ))}
              </div>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSPlanDetailCoverage;
