import { LinkButton } from '@bswing/uhc-core';

const MSPlanListHeader = ({ showInformationAdded }) => {
  return (
    <div className="container container-lg">
      <div className="row py-20 py-lg-30">
        <div className="column column-12">
          <h1 className="heading-1 mb-20">
            AARP® Medicare Supplement Insurance Plans insured by
            UnitedHealthcare in your ZIP Code
          </h1>
          <div className="d-none d-lg-block paragraph mb-30">
            Medicare Supplement plans help fill the gaps and may cover some or
            all of the costs not covered by Medicare Parts A and B.
            <br />
            These plans have predictable-out-pocket expenses along with 100%
            certainty that you can keep your doctor.
          </div>
          <div className="bg-gray-2 rounded p-20">
            <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between gap-20">
              {showInformationAdded && (
                <div>
                  <b>Edit your information to get a personal rate quote.</b>{' '}
                  Premiums shown below are lowest available.
                </div>
              )}
              {!showInformationAdded && (
                <div>
                  <b>Add your information to get a personal rate quote.</b>{' '}
                  Premiums shown below are lowest available.
                </div>
              )}
              <LinkButton>
                <span className="d-flex align-items-center gap-5">
                  <img
                    className="d-block"
                    src="/assets/icons/add-box@2x.png"
                    alt=""
                    style={{ height: 24, width: 25 }}
                  />
                  <span>
                    {showInformationAdded
                      ? 'Edit your information'
                      : 'Add your information'}
                  </span>
                </span>
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSPlanListHeader;
