import { Button, LinkButton } from '@bswing/uhc-core';
import PropTypes from 'prop-types';
import Pluralize from 'react-pluralize';

const CompareFooter = (props) => {
  const { isShown, planCount } = props;

  if (!isShown) {
    return null;
  }

  return (
    <div className="d-none d-lg-block fixed-bottom bg-teal-quaternary shadow">
      <div className="container container-lg py-30">
        <div className="row">
          <div className="column column-12">
            <div className="d-flex align-items-center gap-20">
              <LinkButton>Clear selected plans</LinkButton>
              <span className="flex"></span>
              <LinkButton>Compare all plans</LinkButton>
              <Button isOutlined={true} isSecondary={true}>
                Compare <Pluralize singular={'Plan'} count={planCount} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CompareFooter.propTypes = {
  isShown: PropTypes.bool,
  planCount: PropTypes.number
};

CompareFooter.defaultProps = {
  isShown: true,
  planCount: 0
};

export default CompareFooter;
