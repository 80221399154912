import { LinkButton, Modal } from '@bswing/uhc-core';
import { Link } from 'react-router-dom';

const AddSavedItemsModal = ({
  data = {},
  isShown = false,
  onConfirm = () => {},
  onClose = () => {}
}) => {
  return (
    <Modal
      className="uhc-accent"
      isFullscreen={false}
      isShown={isShown}
      title={<div>&nbsp;</div>}
      onCloseComplete={onClose}
      onOpenComplete={() => {}}
      aria-label="Added to your guest account"
      style={{ width: 570 }}
    >
      <div className="px-20 pb-40 px-lg-80">
        <div className="d-flex flex-column align-items-center gap-40">
          <h2 className="text-center">Added to your guest account</h2>
          <div className="border border-rounded-sm overflow-hidden width-100">
            <div className="bg-blue-primary p-20">
              <h3 className="heading-3 text-white mb-10">{data?.name}</h3>
              <div className="text-bold text-white">{data?.description}</div>
            </div>
            <div className="d-flex align-items-start gap-40 p-20">
              {data?.monthlyPremium && (
                <div>
                  <div className="text-small">Monthly Premium</div>
                  <div
                    className="heading-3"
                    dangerouslySetInnerHTML={{ __html: data?.monthlyPremium }}
                  />
                </div>
              )}
              {data?.outOfPocketMax && (
                <div className="flex-1">
                  <div className="text-small">Out of Pocket Maximum</div>
                  <div
                    className="heading-3"
                    dangerouslySetInnerHTML={{ __html: data?.outOfPocketMax }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="d-flex flex-column align-items-center gap-30">
            <Link
              className="uhc-button uhc-button--secondary"
              to="/account/dashboard"
              onClick={onClose}
            >
              View your guest account
            </Link>
            <LinkButton onClick={onClose}>Keep browsing plans</LinkButton>
          </div>
        </div>
      </div>
      <div className="bg-gray-2 d-flex align-items-start align-items-lg-center p-20 px-lg-80 py-lg-30">
        <img
          className="d-block mr-20"
          src="/assets/images/ic_Heart_2C_onBlue@2x.png"
          alt=""
          style={{
            width: 48,
            height: 48
          }}
        />
        <div>
          <span className="text-bold">Your guest account</span> is a place to keep
          track of your drugs, doctors and saved plans.
        </div>
      </div>
    </Modal>
  );
};

export default AddSavedItemsModal;
