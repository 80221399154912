import FilterGroup from 'components/filter-group/filter-group';
import { MSFilters } from 'components/filter-group/filter-group-data';

const MSPlanListFilters = () => {
  return (
    <div>
      <div className="heading-5 text-blue-primary mb-10">Filter plans</div>
      <div className="mb-20">
        Selecting a filter will change results immediately.
      </div>
      {MSFilters.map((filterGroup, index) => (
        <div className="border-top mb-20 pt-15" key={index}>
          <FilterGroup
            contentPrompt={filterGroup.contentPrompt}
            header={filterGroup.name}
            defaultExpanded={false}
            id={`ms-filter-group-${index}`}
            filters={filterGroup.filters}
          >
            {filterGroup.content}
          </FilterGroup>
        </div>
      ))}
    </div>
  );
};

export default MSPlanListFilters;
