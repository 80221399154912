import { Button, LinkButton } from '@bswing/uhc-core';
import CollapseMenu from 'components/collapse-menu/collapse-menu';
import collapseMenuExampleData from 'components/collapse-menu/collapse-menu-example-data';
import SideNav from 'components/side-nav/side-nav';
import { Fragment } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ShopperProfileHeader from './components/header';
import { mockNavData } from './data/nav-data';
import ShopperProfileDisclaimers from './partials/disclaimers';

const ShopperProfileLayout = ({ children, isAuthenticated = false }) => {
  let location = useLocation();

  const sideNavItems = mockNavData.map((item) => {
    return {
      ...item,
      isActive: location.pathname.includes(item.href)
    };
  });

  const collapseMenuItems = collapseMenuExampleData.map((item) => {
    return {
      ...item,
      isActive: location.pathname.includes(item.href)
    };
  });

  return (
    <Fragment>
      <ShopperProfileHeader isAuthenciated={isAuthenticated} />
      <div className="container container-lg mb-40 pt-80 pt-lg-100">
        <div className="row">
          <div className="column column-12 column-lg-3 mb-30 mb-lg-0">
            <div className="d-none d-lg-block mb-30">
              <SideNav items={sideNavItems} />
            </div>
            <div className="d-block d-lg-none">
              <CollapseMenu navData={collapseMenuItems} />
            </div>
            <div className="d-none d-lg-block border border-rounded-sm p-20">
              <div className="d-flex align-items-center gap-10 mb-15">
                <img
                  src="/assets/icons/gold-star@2x.png"
                  alt=""
                  style={{ width: 20, height: 20 }}
                />
                <LinkButton>Plan recommendations</LinkButton>
              </div>
              <div className="mb-15">
                View your top plans based on your needs.
              </div>
              <Button isOutlined={true} isSecondary={true}>
                View your results
              </Button>
            </div>
          </div>
          <div className="column column-12 column-lg-9">
            {children || <Outlet />}
          </div>
        </div>
      </div>
      <ShopperProfileDisclaimers />
    </Fragment>
  );
};

export default ShopperProfileLayout;
