const mockNavTabs = [
  {
    id: 'saved-plans',
    name: 'Saved plans',
    href: '/account/plans/saved',
    current: false
  },
  {
    id: 'recommended-plans',
    name: 'Recommended plan',
    href: '/account/plans/recommended',
    current: false
  },
  {
    id: 'applications',
    name: 'Your applications',
    href: '/account/plans/applications',
    current: false
  }
];

const mockNavData = [
  {
    label: 'Dashboard',
    href: '/account/dashboard',
    icon: (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={true}
        focusable={false}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.333008 3.66536H3.66634V0.332031H0.333008V3.66536V3.66536ZM5.33301 13.6654H8.66634V10.332H5.33301V13.6654V13.6654ZM0.333008 13.6654H3.66634V10.332H0.333008V13.6654V13.6654ZM0.333008 8.66536H3.66634V5.33203H0.333008V8.66536V8.66536ZM5.33301 8.66536H8.66634V5.33203H5.33301V8.66536V8.66536ZM10.333 0.332031V3.66536H13.6663V0.332031H10.333V0.332031ZM5.33301 3.66536H8.66634V0.332031H5.33301V3.66536V3.66536ZM10.333 8.66536H13.6663V5.33203H10.333V8.66536V8.66536ZM10.333 13.6654H13.6663V10.332H10.333V13.6654V13.6654Z"
          fill="currentColor"
        />
      </svg>
    ),
    isActive: false
  },
  {
    label: 'Your plans',
    href: '/account/plans',
    icon: (
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={true}
        focusable={false}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.75 0.5C11.3 0.5 9.90832 1.175 8.99999 2.24167C8.09166 1.175 6.69999 0.5 5.24999 0.5C2.68332 0.5 0.666656 2.51667 0.666656 5.08333C0.666656 8.23333 3.49999 10.8 7.79166 14.7L8.99999 15.7917L10.2083 14.6917C14.5 10.8 17.3333 8.23333 17.3333 5.08333C17.3333 2.51667 15.3167 0.5 12.75 0.5ZM9.08336 13.4583L9.00003 13.5417L8.9167 13.4583C4.95003 9.86667 2.33336 7.49167 2.33336 5.08333C2.33336 3.41667 3.58336 2.16667 5.25003 2.16667C6.53336 2.16667 7.78336 2.99167 8.22503 4.13333H9.78336C10.2167 2.99167 11.4667 2.16667 12.75 2.16667C14.4167 2.16667 15.6667 3.41667 15.6667 5.08333C15.6667 7.49167 13.05 9.86667 9.08336 13.4583Z"
          fill="currentColor"
        />
      </svg>
    ),
    isActive: false
  },
  {
    label: 'Your drugs',
    href: '/account/drugs',
    icon: (
      <svg
        width="8"
        height="18"
        viewBox="0 0 8 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={true}
        focusable={false}
      >
        <rect
          x="1.20829"
          y="0.563151"
          width="6.25167"
          height="16.3457"
          rx="3.12583"
          stroke="currentColor"
          strokeWidth="0.958333"
        />
        <path
          d="M7.21807 7.29297L1.45007 10.177V13.061C1.45007 15.224 2.89207 16.666 4.33407 16.666C5.48767 16.666 7.21807 15.945 7.21807 13.061V7.29297Z"
          fill="currentColor"
        />
      </svg>
    ),
    isActive: false
  },
  {
    label: 'Your doctors & dentists',
    href: '/account/doctors',
    icon: (
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={true}
        focusable={false}
      >
        <path
          d="M5.69223 9.76788C8.24123 9.76788 10.3076 7.78986 10.3076 3.83381V0.537109H8.32959M5.69223 9.76788C3.14322 9.76788 1.07684 7.78986 1.07684 3.83381V0.537109H3.05487M5.69223 9.76788V14.3833"
          stroke="currentColor"
          strokeWidth="1.04167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.69223 12.8457V14.7688C5.69223 16.2557 7.06982 17.4611 8.76915 17.4611C10.4685 17.4611 11.8461 16.2557 11.8461 14.7688V13.4226"
          stroke="currentColor"
          strokeWidth="1.04167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3372 11.6031C13.3372 12.3938 12.6961 13.0349 11.9053 13.0349C11.1146 13.0349 10.4735 12.3938 10.4735 11.6031C10.4735 10.8123 11.1146 10.1712 11.9053 10.1712C12.6961 10.1712 13.3372 10.8123 13.3372 11.6031Z"
          stroke="currentColor"
          strokeWidth="1.04167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    isActive: false
  }
];

export { mockNavData, mockNavTabs };
