import { LinkButton } from '@bswing/uhc-core';
import { withBreakpoints } from 'react-breakpoints';

const HelpSection = (props) => {
  const { currentBreakpoint } = props;

  return (
    <div className="py-40 py-lg-100">
      <div className="container container-lg">
        <div className="row">
          <div className="column column-12 column-lg-10 column-lg-shift-1">
            <div className="d-flex flex-column-reverse flex-lg-row align-items-center">
              <div className="text-center text-lg-left">
                <div className="heading-2 mb-10 mb-lg-20">
                  We're here to help
                </div>
                <div className="mb-20">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div>
                <div className="mb-10 text-blue-primary text-bold text-large text-lg-extra-large">
                  <span className="text-serif">Call 1-855-888-1640</span>
                  <span className="text-normal"> / TTY 711</span>
                </div>
                <div className="mb-20 mb-lg-30">
                  8 a.m. to 8 p.m., 7 days a week
                </div>
                <div>
                  <LinkButton>
                    Find a Sales Agent in your area{' '}
                    <img
                      className="ml-5"
                      src="/assets/icons/external-blue-icon@2x.png"
                      alt="external icon"
                      style={{ height: 14, width: 14, verticalAlign: -1 }}
                    />
                  </LinkButton>
                </div>
              </div>
              <img
                className="d-block height-auto ml-lg-100 mb-20 mb-lg-0"
                src="/assets/images/illustration-support-rgb-full@2x.png"
                style={
                  currentBreakpoint === 'mobile'
                    ? { width: 200, maxWidth: '100%' }
                    : { width: 330, maxWidth: '100%' }
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withBreakpoints(HelpSection);
