import { useLottie } from 'lottie-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './tabs.scss';

const TabIcon = ({ animationData, shouldPlay }) => {
  const options = {
    animationData: animationData,
    initialSegment: [0, animationData.op],
    loop: false,
    autoplay: false
  };

  const { View, play, goToAndStop } = useLottie(options);

  if (shouldPlay) {
    play();
  } else {
    goToAndStop(0);
  }

  return View;
};

const Tabs = ({ tabs, ...rest }) => {
  const location = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    const index = tabs.findIndex((tab) => tab.href === location.pathname);
    if (index === -1) {
      setActiveTabIndex(0);
      return;
    } else {
      setActiveTabIndex(index + 1);
    }
  }, [location.pathname, tabs]);

  return (
    <div className="uhc-tabs__container">
      <div className="uhc-tabs__border">
        <div className="container container-lg">
          <div className="row">
            <div className="column column-12">
              <div className="uhc-tabs" {...rest}>
                <nav aria-label="Tabs">
                  <Link
                    to="/health-plans/plan-summary"
                    className={
                      activeTabIndex === 0
                        ? ' uhc-tabs__tab uhc-tabs__first-tab uhc-tabs__tab--active'
                        : 'uhc-tabs__tab uhc-tabs__first-tab'
                    }
                  >
                    <span>There are 5 plans available in your area</span>
                  </Link>
                  {tabs.map((tab, index) => (
                    <Link
                      className={
                        activeTabIndex === index + 1
                          ? ' uhc-tabs__tab uhc-tabs__tab--active'
                          : 'uhc-tabs__tab'
                      }
                      key={tab.name}
                      to={tab.href}
                      aria-current={
                        activeTabIndex === index + 1 ? 'page' : undefined
                      }
                    >
                      <div className="uhc-tabs__tab-icon">
                        <TabIcon
                          animationData={tab.animationData}
                          shouldPlay={activeTabIndex === index + 1}
                        />
                      </div>

                      <span className="uhc-tabs__tab-mobile-plan-count">
                        {tab.planCount}
                      </span>
                      <span className="d-block">{tab.name}</span>
                      <img
                        className="uhc-tabs__tab-mobile-arrow"
                        src="/assets/icons/arrow-forward-icon@2x.png"
                        alt=""
                        style={{ width: 16, height: 16 }}
                      />
                      <span className="uhc-tabs__tab-desktop-plan-count">
                        {tab.planCount} plans available
                      </span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array
};

Tabs.defaultProps = {
  tabs: []
};

export default Tabs;
