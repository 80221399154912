import { LinkButton } from '@bswing/uhc-core';
import { Link } from 'react-router-dom';

const ShopperProfileMoreOptionsCTA = () => {
  return (
    <div className="mb-30">
      <div className="bg-gray-2 border-rounded-md d-flex flex-column flex-lg-row align-items-lg-center gap-20 p-20">
        <img
          className="d-none d-lg-block mb-n20"
          src="/assets/images/image-34.png"
          alt=""
          style={{ height: 'auto', width: 95 }}
        />
        <div className="flex">
          <p className="text-large text-bold mb-5">Looking for more options?</p>
          <p>There are 20 plans available in your area.</p>
        </div>
        <div className="d-none d-lg-block">
          <Link
            className="uhc-button uhc-button--outlined uhc-button--secondary"
            to="/health-plans/plan-summary"
          >
            Browse all plans
          </Link>
        </div>
        <div className="d-block d-lg-none">
          <LinkButton
            className="underline"
            as={Link}
            to="/health-plans/plan-summary"
          >
            Browse all plans
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

export default ShopperProfileMoreOptionsCTA;
