import { LinkButton } from '@bswing/uhc-core';
import { withBreakpoints } from 'react-breakpoints';

const MedicareAdvantagePlansSection = (props) => {
  const { currentBreakpoint } = props;

  return (
    <div className="bg-gray-2 py-40 py-lg-100">
      <div className="container container-lg">
        <div className="row">
          <div className="column column-12 column-lg-10 column-lg-shift-1">
            <div className="d-flex flex-column flex-lg-row align-items-center">
              <div className="mr-lg-60 mb-20 mb-lg-0 flex-auto">
                <img
                  className="d-block height-auto"
                  src="/assets/images/medicare-advantage-plans@2x.png"
                  style={
                    currentBreakpoint === 'mobile'
                      ? { width: 330, maxWidth: '100%' }
                      : { width: 515, maxWidth: '100%' }
                  }
                  alt=""
                />
              </div>
              <div className="text-center text-lg-left flex-auto">
                <div className="heading-2 text-dark mb-10 mb-lg-20">
                  See all our Medicare Advantage plans
                </div>
                <div className="mb-20 text-lg-large">
                  Sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </div>
                <div>
                  <LinkButton>
                    View all 9 plans{' '}
                    <img
                      className="ml-5"
                      src="/assets/icons/chevron-right-icon@2x.png"
                      alt=""
                      style={{ width: 8, height: 12 }}
                    />
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withBreakpoints(MedicareAdvantagePlansSection);
