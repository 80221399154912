const MSPlanListImportantInformation = (props) => {
  return (
    <div className="container container-lg py-40 py-lg-60">
      <div className="row">
        <div className="column column-12">
          <div className="border border-thick rounded">
            <div className="uhc-vpp-scroll-pane" tabIndex={0}>
              <div className="d-flex flex-column gap-20 p-30">
                <h2 className="heading-3">Important Information</h2>
                <p>The amounts shown on this page are applicable for 2022.</p>
                <p id="footnote-1">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">1</sup> The
                  premium (including discounts) is for a person who is an age 65
                  female non-tobacco user who can answer "no" to all of the
                  health questions on the Application Form when applying. The
                  answers to questions on your Application Form will be used to
                  determine which premium applies to you and your actual monthly
                  premium will be determined when you apply. All premiums are
                  subject to change. Any premium change will apply to all
                  members of the same class insured under your Plan who reside
                  in your state. [The premium is based on the information you
                  entered, is for the current month and includes discounts, if
                  applicable. The answers to questions on your Application Form
                  will be used to determine which premium applies to you and
                  your actual monthly premium will be determined when you apply.
                  All premiums are subject to change. Any premium change will
                  apply to all members of the same class insured under your plan
                  who reside in your state. These premiums are not for current
                  insured members. If you are already an insured member, please
                  call for information.]
                </p>
                <p id="footnote-2">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">2</sup> Select
                  Plan G offers the same standard benefits as Plan G, except you
                  must use a network hospital for covered Inpatient Hospital
                  services under Medicare Part A. You should also verify that
                  your chosen physician is affiliated with a network hospital.
                </p>
                <p id="footnote-3">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">3</sup> Select
                  Plan N offers the same standard benefits as Plan N, except you
                  must use a network hospital for covered Inpatient Hospital
                  services under Medicare Part A. You should also verify that
                  your chosen physician is affiliated with a network hospital.
                </p>
                <p className="text-bold" id="footnote-10">
                  <span className="visually-hidden">Footnote</span>{' '}
                  <sup className="text-bold text-blue-secondary">10</sup> These
                  offers are only available to insured members covered under an
                  AARP Medicare Supplement Plan from UnitedHealthcare Insurance
                  Company. These are additional insured member services apart
                  from the AARP Medicare Supplement Plan benefits, are not
                  insurance programs, are subject to geographical availability
                  and may be discontinued at any time.
                </p>
                <p>
                  None of these services are a substitute for the advice of a
                  doctor or should be used for emergency or urgent care needs.
                  In an emergency, call 911 or go to the nearest emergency room.
                  Participation in the Renew Active® program is voluntary. Renew
                  Active includes standard fitness membership and other
                  offerings. Fitness membership equipment, classes, personalized
                  fitness plans, caregiver access and events may vary by
                  location. Consult your doctor prior to beginning an exercise
                  program or making changes to your lifestyle or health care
                  routine. The Renew Active program varies by plan/area. Gym
                  network may vary in local market. Certain services, discounts,
                  classes, events, and online fitness offerings are provided by
                  third parties not affiliated with UnitedHealthcare Insurance
                  Company. Participation in these third-party services are
                  subject to your acceptance of their respective terms and
                  policies. The information provided through these services is
                  for informational purposes only and is not a substitute for
                  the advice of a doctor.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSPlanListImportantInformation;
