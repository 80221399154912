import { LinkButton } from '@bswing/uhc-core';
import { withBreakpoints } from 'react-breakpoints';

const AddLinkButton = ({ onAddDoctorsClick }) => (
  <LinkButton className="text-lg-large d-block" onClick={onAddDoctorsClick}>
    <span className="d-flex align-items-center">
      <img
        className="d-block mr-5"
        src="/assets/icons/add-box@2x.png"
        alt=""
        style={{ height: 24, width: 25 }}
      />
      <span>
        Add your doctors
        <span className="d-none d-lg-inline">
          {' '}
          to see if they are in network
        </span>
      </span>
    </span>
  </LinkButton>
);

const CoverageDoctorsTable = ({
  savedDoctors = [],
  onAddDoctorsClick,
  currentBreakpoint
}) => {
  if (savedDoctors.length > 0) {
    return (
      <div className="border border-rounded-sm overflow-hidden">
        <table style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr className="bg-gray-2">
              <th
                className="text-bold text-blue-primary p-20"
                style={{ width: '35%' }}
              >
                <span>Your doctors</span>{' '}
                <LinkButton
                  className="ml-5 underline"
                  onClick={onAddDoctorsClick}
                >
                  Edit
                </LinkButton>
              </th>
              <th
                className="text-bold text-blue-primary p-20 d-none d-lg-table-cell"
                style={{ width: 'auto' }}
              >
                Coverage
              </th>
            </tr>
          </thead>
          <tbody>
            {savedDoctors.map((doctor, index) => (
              <tr className={index % 2 !== 0 ? 'bg-gray-2' : ''} key={index}>
                <td className="p-20 valign-top" style={{ width: '35%' }}>
                  <div className="d-flex align-items-start">
                    <div className="mr-20">
                      <img
                        className="d-block"
                        src={`/assets/icons/provider-search-${doctor.type}@2x.png`}
                        alt=""
                        style={{
                          width: currentBreakpoint === 'desktop' ? 48 : 32,
                          height: currentBreakpoint === 'desktop' ? 48 : 32
                        }}
                      />
                    </div>
                    <div>
                      <div className="text-bold text-lg-large mb-5">
                        {doctor.name}
                      </div>
                      {/* <div>{doctor.selectedMedicalGroup}</div> */}
                      <div>{doctor.address}</div>
                      <div className="d-block d-lg-none mt-10">
                        <div className="text-bold mb-5">Coverage:</div>
                        <div>
                          {doctor.inNetwork ? (
                            <img
                              className="mr-10"
                              src="/assets/icons/check-icon-alt@2x.png"
                              alt="Covered"
                              style={{ width: 17, height: 11 }}
                            />
                          ) : (
                            <img
                              className="mr-10"
                              src="/assets/icons/not-covered-x@2x.png"
                              alt="Not Covered"
                              style={{ width: 17, height: 13 }}
                            />
                          )}
                          <span>
                            {doctor.inNetwork ? 'In-Network' : 'Out-of-Network'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  className="p-20 valign-top d-none d-lg-table-cell"
                  style={{ width: 'auto' }}
                >
                  <div className="d-flex align-items-start">
                    <div className="mr-10">
                      {doctor.inNetwork ? (
                        <img
                          src="/assets/icons/check-icon-alt@2x.png"
                          alt="Covered"
                          style={{ width: 17, height: 11 }}
                        />
                      ) : (
                        <img
                          src="/assets/icons/not-covered-x@2x.png"
                          alt="Not Covered"
                          style={{ width: 17, height: 13 }}
                        />
                      )}
                    </div>
                    <div>
                      <div className="text-lg-large">
                        {doctor.inNetwork ? 'In-Network' : 'Out-of-Network'}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="border border-rounded-sm overflow-hidden">
      <table style={{ tableLayout: 'fixed' }}>
        <tbody>
          <tr>
            <td className="p-20" style={{ width: '35%' }}>
              <span className="text-bold text-lg-large">Your doctors</span>
              <div className="d-block d-lg-none mt-10">
                <AddLinkButton onAddDoctorsClick={onAddDoctorsClick} />
              </div>
            </td>
            <td
              className="p-20 d-none d-lg-table-cell"
              style={{ width: 'auto' }}
            >
              <AddLinkButton onAddDoctorsClick={onAddDoctorsClick} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default withBreakpoints(CoverageDoctorsTable);
