import ScrollToTop from 'components/scroll-to-top/scroll-to-top';
import { BrowserRouter } from 'react-router-dom';
import ShopperProfilePrototype from './shopper-profile/shopper-profile';

const Prototypes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ShopperProfilePrototype />
    </BrowserRouter>
  );
};

export default Prototypes;
