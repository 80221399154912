const collapseMenuExampleData = [
  {
    label: 'Dashboard',
    href: '/account/dashboard',
    icon: (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={true}
        focusable={false}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.333984 3.66683H3.66732V0.333496H0.333984V3.66683ZM5.33398 13.6668H8.66732V10.3335H5.33398V13.6668ZM0.333984 13.6668H3.66732V10.3335H0.333984V13.6668ZM0.333984 8.66683H3.66732V5.3335H0.333984V8.66683ZM5.33398 8.66683H8.66732V5.3335H5.33398V8.66683ZM10.334 0.333496V3.66683H13.6673V0.333496H10.334ZM5.33398 3.66683H8.66732V0.333496H5.33398V3.66683ZM10.334 8.66683H13.6673V5.3335H10.334V8.66683ZM10.334 13.6668H13.6673V10.3335H10.334V13.6668Z"
          fill="currentColor"
        />
      </svg>
    ),
    isActive: false
  },
  {
    label: 'Saved plans',
    href: '/account/plans/saved',
    icon: (
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={true}
        focusable={false}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.75 0.5C11.3 0.5 9.90832 1.175 8.99999 2.24167C8.09166 1.175 6.69999 0.5 5.24999 0.5C2.68332 0.5 0.666656 2.51667 0.666656 5.08333C0.666656 8.23333 3.49999 10.8 7.79166 14.7L8.99999 15.7917L10.2083 14.6917C14.5 10.8 17.3333 8.23333 17.3333 5.08333C17.3333 2.51667 15.3167 0.5 12.75 0.5ZM9.08336 13.4583L9.00003 13.5417L8.9167 13.4583C4.95003 9.86667 2.33336 7.49167 2.33336 5.08333C2.33336 3.41667 3.58336 2.16667 5.25003 2.16667C6.53336 2.16667 7.78336 2.99167 8.22503 4.13333H9.78336C10.2167 2.99167 11.4667 2.16667 12.75 2.16667C14.4167 2.16667 15.6667 3.41667 15.6667 5.08333C15.6667 7.49167 13.05 9.86667 9.08336 13.4583Z"
          fill="currentColor"
        />
      </svg>
    ),
    isActive: false
  },
  {
    label: 'Recommended plans',
    href: '/account/plans/recommended',
    icon: (
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={true}
        focusable={false}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3327 6.11683L11.341 5.60016L8.99935 0.0834961L6.65768 5.6085L0.666016 6.11683L5.21602 10.0585L3.84935 15.9168L8.99935 12.8085L14.1493 15.9168L12.791 10.0585L17.3327 6.11683V6.11683ZM8.99935 11.2502L5.86602 13.1418L6.69935 9.57516L3.93268 7.17516L7.58268 6.8585L8.99935 3.50016L10.4243 6.86683L14.0743 7.1835L11.3077 9.5835L12.141 13.1502L8.99935 11.2502V11.2502Z"
          fill="currentColor"
        />
      </svg>
    ),
    isActive: false
  },
  {
    label: 'Your applications',
    href: '/account/plans/applications',
    icon: (
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={true}
        focusable={false}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8333 2.33366H10.35C10 1.36699 9.08333 0.666992 8 0.666992C6.91667 0.666992 6 1.36699 5.65 2.33366H2.16667C1.25 2.33366 0.5 3.08366 0.5 4.00033V15.667C0.5 16.5837 1.25 17.3337 2.16667 17.3337H13.8333C14.75 17.3337 15.5 16.5837 15.5 15.667V4.00033C15.5 3.08366 14.75 2.33366 13.8333 2.33366V2.33366ZM8 2.33366C8.45833 2.33366 8.83333 2.70866 8.83333 3.16699C8.83333 3.62533 8.45833 4.00033 8 4.00033C7.54167 4.00033 7.16667 3.62533 7.16667 3.16699C7.16667 2.70866 7.54167 2.33366 8 2.33366V2.33366ZM9.66667 14.0003H3.83333V12.3337H9.66667V14.0003V14.0003ZM12.1667 10.667H3.83333V9.00033H12.1667V10.667V10.667ZM12.1667 7.33366H3.83333V5.66699H12.1667V7.33366V7.33366Z"
          fill="currentColor"
        />
      </svg>
    ),
    isActive: false
  },
  {
    label: 'Your drugs',
    href: '/account/drugs',
    icon: (
      <svg
        width="8"
        height="18"
        viewBox="0 0 8 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={true}
        focusable={false}
      >
        <rect
          x="1.20829"
          y="0.563151"
          width="6.25167"
          height="16.3457"
          rx="3.12583"
          stroke="currentColor"
          strokeWidth="0.958333"
        />
        <path
          d="M7.21807 7.29297L1.45007 10.177V13.061C1.45007 15.224 2.89207 16.666 4.33407 16.666C5.48767 16.666 7.21807 15.945 7.21807 13.061V7.29297Z"
          fill="currentColor"
        />
      </svg>
    ),
    isActive: false
  },
  {
    label: 'Your doctors & dentists',
    href: '/account/doctors',
    icon: (
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={true}
        focusable={false}
      >
        <path
          d="M5.69223 9.76788C8.24123 9.76788 10.3076 7.78986 10.3076 3.83381V0.537109H8.32959M5.69223 9.76788C3.14322 9.76788 1.07684 7.78986 1.07684 3.83381V0.537109H3.05487M5.69223 9.76788V14.3833"
          stroke="currentColor"
          strokeWidth="1.04167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.69223 12.8457V14.7688C5.69223 16.2557 7.06982 17.4611 8.76915 17.4611C10.4685 17.4611 11.8461 16.2557 11.8461 14.7688V13.4226"
          stroke="currentColor"
          strokeWidth="1.04167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3372 11.6031C13.3372 12.3938 12.6961 13.0349 11.9053 13.0349C11.1146 13.0349 10.4735 12.3938 10.4735 11.6031C10.4735 10.8123 11.1146 10.1712 11.9053 10.1712C12.6961 10.1712 13.3372 10.8123 13.3372 11.6031Z"
          stroke="currentColor"
          strokeWidth="1.04167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    isActive: false
  }
];

export default collapseMenuExampleData;
