const applicationsFaqData = [
  {
    question: 'Can I talk to someone if I have questions?',
    answer: 'You can call our Medicare sales experts toll-free, 7 a.m. to 11 p.m. ET Monday - Friday, 9 a.m. to 5 p.m. ET Saturday: 1-855-939-4744 (TTY 711). You can find out more about Medicare and your coverage options in Medicare Made Clear.'
  },
  {
    question: 'How do I save my progress?',
    answer: 'Creating an account allows you to save the information you enter so you can save your shopping progress. Creating an account is easy. It only requires an email address, username and password. If you choose to become a member, your login information will remain the same.'
  },
  {
    question: "What happens with my information?",
    answer: 'If you create an account, your information will be secured using your HealthSafe ID. If you haven\'t created an account, your information will be stored on your personal computer and will be removed when you clear your cache and cookies.'
  },
  {
    question: "How do I complete an in-progress application?",
    answer: 'When you visit your account, you will see any in-progress applications on your dashboard, and you will be able to resume where you left off.'
  },
  {
    question: "How do I see all plans?",
    answer: 'You can see all plans by clicking the "Shop all plans in your area" button on the plans page. You can also click here to view all plans'
  }
];

const savedPlansFaqData = [
  {
    question: 'Can I talk to someone if I have questions?',
    answer: 'You can call our Medicare sales experts toll-free, 7 a.m. to 11 p.m. ET Monday - Friday, 9 a.m. to 5 p.m. ET Saturday: 1-855-939-4744 (TTY 711). You can find out more about Medicare and your coverage options in Medicare Made Clear.'
  },
  {
    question: 'How do I save my progress?',
    answer: 'Creating an account allows you to save the information you enter so you can save your shopping progress. Creating an account is easy. It only requires an email address, username and password. If you choose to become a member, your login information will remain the same.'
  },
  {
    question: "What happens with my information?",
    answer: 'If you create an account, your information will be secured using your HealthSafe ID. If you haven\'t created an account, your information will be stored on your personal computer and will be removed when you clear your cache and cookies.'
  },
  {
    question: "How do I complete an in-progress application?",
    answer: 'When you visit your account, you will see any in-progress applications on your dashboard, and you will be able to resume where you left off.'
  },
  {
    question: "How do I see all plans?",
    answer: 'You can see all plans by clicking the "Shop all plans in your area" button on the plans page. You can also click here to view all plans'
  }
];

const recommendedPlansFaqData = [
  {
    question: 'Can I talk to someone if I have questions?',
    answer: 'You can call our Medicare sales experts toll-free, 7 a.m. to 11 p.m. ET Monday - Friday, 9 a.m. to 5 p.m. ET Saturday: 1-855-939-4744 (TTY 711). You can find out more about Medicare and your coverage options in Medicare Made Clear.'
  },
  {
    question: 'How do I save my progress?',
    answer: 'Creating an account allows you to save the information you enter so you can save your shopping progress. Creating an account is easy. It only requires an email address, username and password. If you choose to become a member, your login information will remain the same.'
  },
  {
    question: "What happens with my information?",
    answer: 'If you create an account, your information will be secured using your HealthSafe ID. If you haven\'t created an account, your information will be stored on your personal computer and will be removed when you clear your cache and cookies.'
  },
  {
    question: "How do I complete an in-progress application?",
    answer: 'When you visit your account, you will see any in-progress applications on your dashboard, and you will be able to resume where you left off.'
  },
  {
    question: "How do I see all plans?",
    answer: 'You can see all plans by clicking the "Shop all plans in your area" button on the plans page. You can also click here to view all plans'
  }
];

const doctorsFaqData = [
  {
    question: 'Can I talk to someone if I have questions?',
    answer: 'You can call our Medicare sales experts toll-free, 7 a.m. to 11 p.m. ET Monday - Friday, 9 a.m. to 5 p.m. ET Saturday: 1-855-939-4744 (TTY 711). You can find out more about Medicare and your coverage options in Medicare Made Clear.'
  },
  {
    question: 'How do I save my progress?',
    answer: 'Creating an account allows you to save the information you enter so you can save your shopping progress. Creating an account is easy. It only requires an email address, username and password. If you choose to become a member, your login information will remain the same.'
  },
  {
    question: "What happens with my information?",
    answer: 'If you create an account, your information will be secured using your HealthSafe ID. If you haven\'t created an account, your information will be stored on your personal computer and will be removed when you clear your cache and cookies.'
  },
  {
    question: "How do I complete an in-progress application?",
    answer: 'When you visit your account, you will see any in-progress applications on your dashboard, and you will be able to resume where you left off.'
  },
  {
    question: "How do I see all plans?",
    answer: 'You can see all plans by clicking the "Shop all plans in your area" button on the plans page. You can also click here to view all plans'
  }
];

const drugsFaqData = [
  {
    question: 'Can I talk to someone if I have questions?',
    answer: 'You can call our Medicare sales experts toll-free, 7 a.m. to 11 p.m. ET Monday - Friday, 9 a.m. to 5 p.m. ET Saturday: 1-855-939-4744 (TTY 711). You can find out more about Medicare and your coverage options in Medicare Made Clear.'
  },
  {
    question: 'How do I save my progress?',
    answer: 'Creating an account allows you to save the information you enter so you can save your shopping progress. Creating an account is easy. It only requires an email address, username and password. If you choose to become a member, your login information will remain the same.'
  },
  {
    question: "What happens with my information?",
    answer: 'If you create an account, your information will be secured using your HealthSafe ID. If you haven\'t created an account, your information will be stored on your personal computer and will be removed when you clear your cache and cookies.'
  },
  {
    question: "How do I complete an in-progress application?",
    answer: 'When you visit your account, you will see any in-progress applications on your dashboard, and you will be able to resume where you left off.'
  },
  {
    question: "How do I see all plans?",
    answer: 'You can see all plans by clicking the "Shop all plans in your area" button on the plans page. You can also click here to view all plans'
  }
];

export {
  applicationsFaqData,
  doctorsFaqData,
  drugsFaqData,
  recommendedPlansFaqData,
  savedPlansFaqData
};
