import MSPlanDetail from 'pages/ms-plan-detail/ms-plan-detail';
import PlanDetail from 'pages/plan-detail/plan-detail';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import mockPlansData from '../data/mock-plans.json';

const ShopperProfilePlanDetailPage = ({
  isAuthenticated = false,
  savedDoctors = [],
  savedDrugs = [],
  savedPlans = [],
  onAddDoctorsClick = () => {},
  onAddDrugsClick = () => {},
  onPlanSaveToggle = () => {}
}) => {
  const navigate = useNavigate();
  const { planId } = useParams();
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    const plan = mockPlansData.find((p) => p.id === planId);
    if (!plan) {
      navigate('/health-plans/plan-summary');
    }
    setCurrentPlan(plan);
  }, [navigate, planId]);

  useEffect(() => {
    const isPlanSaved = savedPlans.find((p) => p.id === planId);
    setIsSaved(isPlanSaved);
  }, [planId, savedPlans]);

  const handlePlanSaveToggle = () => {
    onPlanSaveToggle(null, !isSaved, currentPlan);
  };

  if (!currentPlan) {
    return null;
  }

  if (currentPlan?.type === 'MS') {
    return (
      <MSPlanDetail
        isSaved={isSaved}
        planData={currentPlan}
        showCompare={false}
        showInformationAdded={false}
        onPlanSaveToggle={handlePlanSaveToggle}
      />
    );
  }

  return (
    <PlanDetail
      isAuthenticated={isAuthenticated}
      isSaved={isSaved}
      planData={currentPlan}
      savedDoctors={savedDoctors}
      savedDrugs={savedDrugs}
      savedPlans={savedPlans}
      showCompare={false}
      showEnroll={true}
      onAddDoctorsClick={onAddDoctorsClick}
      onAddDrugsClick={onAddDrugsClick}
      onPlanSaveToggle={handlePlanSaveToggle}
    />
  );
};

export default ShopperProfilePlanDetailPage;
