import classNames from 'classnames';
import './empty-state.scss';

const EmptyState = (props) => {
  const { className, children, ...rest } = props;
  const componentClassName = classNames('uhc-empty-state', {}, className);

  return (
    <div className={componentClassName} {...rest}>
      <div className="uhc-empty-state__content">{children}</div>
    </div>
  );
};

export default EmptyState;
