import { LinkButton } from '@bswing/uhc-core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import './zip-code-prompt.scss';

const ZIPCodePrompt = (props) => {
  const { className } = props;
  const [isActive, setIsActive] = useState(true);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsPaused(!isPaused);
    }, 5000);
  }, [isPaused]);

  const handleCloseClick = () => {
    setIsActive(false);
  };

  const componentClassName = classNames(
    'uhc-zcp',
    {
      'uhc-zcp--active': isActive,
      'uhc-zcp--paused': isPaused
    },
    className
  );

  return (
    <div className={componentClassName}>
      <LinkButton aria-describedby="zip-code-prompt">
        <span className="d-flex align-items-center gap-5">
          <span>59102 Billings Yellowstone County</span>
          <img
            src="/assets/icons/pencil-copy@2x.png"
            alt=""
            style={{ height: 12, width: 12 }}
          />
        </span>
      </LinkButton>
      <div className="uhc-zcp__text" id="zip-code-prompt" role="tooltip">
        <div>
          <a href="#link">Update your ZIP Code</a> to view accurate plan details
          for your area
        </div>
        <button
          className="uhc-link-button uhc-zcp__close"
          onClick={handleCloseClick}
        >
          <span aria-hidden="true">&times;</span>
          <span className="visually-hidden">Close</span>
        </button>
      </div>
    </div>
  );
};

ZIPCodePrompt.propTypes = {
  className: PropTypes.string
};

ZIPCodePrompt.defaultProps = {
  isShown: false
};

export default ZIPCodePrompt;
