import { Button, LinkButton } from '@bswing/uhc-core';
import classNames from 'classnames';
import EmptyState from 'components/empty-state/empty-state';
import UtilityTag from 'components/utility-tag/utility-tag';
import { Fragment } from 'react';
import ShopperProfileDrugCoverageCTA from '../components/drug-coverage-cta';
import ShopperProfileFAQs from '../components/faqs';
import ShopperProfileFindPlanCTA from '../components/find-plan-cta';
import { drugsFaqData } from '../data/faq-data';

const ShopperProfileDrugsPage = ({
  data = [],
  onAddDrugsClick = () => {},
  onRemoveDrugClick = () => {}
}) => {
  return (
    <Fragment>
      <div className="mb-30">
        <h2 className="mb-10">Your drugs</h2>
        <p>
          Create a personalized list of drugs to compare costs. Some of your
          prescriptions will be eligible for home delivery. You can add and
          remove drugs as needed.
        </p>
      </div>

      <div className="mb-30">
        <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-20">
          <UtilityTag as="button" onClick={onAddDrugsClick}>
            <span className="d-flex align-items-center">
              <img
                className="d-block mr-10"
                src="/assets/icons/circle-add-icon@2x.png"
                alt=""
                style={{ height: 20, width: 20 }}
              />
              <span className="text-blue-secondary text-bold">
                Add your drugs
              </span>
            </span>
          </UtilityTag>
          <LinkButton className="text-small">
            Import my drugs and doctors
          </LinkButton>
        </div>
      </div>

      {/* Populated State */}
      {data.length > 0 && (
        <Fragment>
          <div className="mb-30">
            <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-20">
              <div>
                <span className="text-bold">Pharmacy:</span> Your estimated drug
                costs are based on prices at a retail chain pharmacy
              </div>
              <LinkButton>Edit</LinkButton>
            </div>
          </div>
          <div className="mb-30">
            <div className="d-flex flex-column gap-30">
              <div className="border border-rounded-sm">
                {data.map((drug, index) => (
                  <div
                    className={classNames(
                      'd-flex align-items-start align-items-lg-center p-20',
                      {
                        'border-top': index > 0,
                        'bg-gray-2': index % 2 !== 0
                      }
                    )}
                    key={index}
                  >
                    <img
                      className="d-block mr-20"
                      src="/assets/images/local-prescription-pickup@2x.png"
                      alt=""
                      style={{
                        width: 48,
                        height: 48
                      }}
                    />
                    <div className="d-flex flex-column flex-lg-row align-items-lg-center flex gap-20 gap-lg-60">
                      <div className="flex">
                        <div className="text-bold mb-5">{drug.name}</div>
                        {drug.package && (
                          <div className="mb-5">{drug.package}</div>
                        )}
                        <div>{drug.dosage}</div>
                      </div>
                      <div className="d-flex align-items-center flex-row gap-20 gap-lg-60">
                        <LinkButton>Edit</LinkButton>
                        <LinkButton onClick={() => onRemoveDrugClick(drug.id)}>
                          Remove
                        </LinkButton>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <ShopperProfileDrugCoverageCTA />
        </Fragment>
      )}

      {/* Empty State */}
      {data.length === 0 && (
        <div className="mb-30">
          <EmptyState>
            <img
              className="d-block mb-20"
              src="/assets/images/local-prescription-pickup@2x.png"
              alt=""
              style={{ width: 48, height: 48 }}
            />
 
            <p className="mb-20">Looks like you don’t have any saved drugs yet</p>
              <Button isSecondary={true} onClick={onAddDrugsClick}>Save your drugs</Button>
            
            
          </EmptyState>
        </div>
      )}
      <ShopperProfileFAQs faqs={drugsFaqData} />
      <ShopperProfileFindPlanCTA />
    </Fragment>
  );
};

export default ShopperProfileDrugsPage;
