import { LinkButton } from '@bswing/uhc-core';
import { Link } from 'react-router-dom';

const ShopperProfileDrugCoverageCTA = () => {
  return (
    <div className="mb-30">
      <div className="d-flex flex-column flex-lg-row align-items-start gap-20 py-lg-30 border-lg-top">
        <img
          className="d-none d-lg-block"
          src="/assets/images/ic_MedicareD_2C_onBlue@2x.png"
          alt=""
          style={{ height: 48, width: 'auto' }}
        />
        <div className="flex">
          <h2 className="heading-3 mb-5 text-gray-8">
            Are your drugs covered?
          </h2>
          <p className="mb-15">
            You can see drug coverage and estimated drug costs as you view and
            compare plans in your area.
          </p>
          <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-20">
            <Link to="/account/plans/saved" className='uhc-button uhc-button--outlined uhc-button--secondary'>
               View your saved plans
            </Link>
            <LinkButton as={Link} to="/health-plans/plan-summary">Browse all plans in your area</LinkButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopperProfileDrugCoverageCTA;
