import toast from 'react-hot-toast';
import Toast from './toast';

const toastFn = (title, message) => {
  toast.custom(
    (t) => (
      <Toast
        message={message}
        title={title}
        visible={t.visible}
        onDismiss={() => toast.dismiss(t.id)}
      />
    ),
    {
      duration: 5000,
      position: 'top-right'
    }
  );
};

const toastController = {
  notify: (title, message) => toastFn(title, message)
};

export default toastController;
