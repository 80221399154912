import CompareFooter from 'components/compare-footer/compare-footer';
import UtilityBar from 'components/utility-bar/utility-bar';
import useScrollPosition from 'hooks/useScrollPosition';
import HelpSection from 'partials/help-section/help-section';
import MedicareAdvantagePlansSection from 'partials/medicare-advantage-plans-section/medicare-advantage-plans-section';
import PlanRecommendationsSection from 'partials/plan-recommendations-section/plan-recommendations-section';
import { Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';
import PlanDetailBenefits from './plan-detail-benefits/plan-detail-benefits';
import PlanDetailCoverage from './plan-detail-coverage/plan-detail-coverage';
import PlanDetailHeader from './plan-detail-header/plan-detail-header';
import PlanDetailStickyHeader from './plan-detail-sticky-header/plan-detail-sticky-header';

const PlanDetail = (props) => {
  const {
    isAuthenticated = false,
    isSaved,
    planData,
    savedDoctors = [],
    savedDrugs = [],
    savedPlans = [],
    showCompare,
    showDentists,
    showDrugPricingUnavailable,
    showEnroll,
    showZipCodePrompt,
    onAddDoctorsClick = () => {},
    onAddDrugsClick = () => {},
    onPlanSaveToggle = () => {}
  } = props;
  const scrollPosition = useScrollPosition();

  const shouldShowStickyHeader = scrollPosition > 500;

  return (
    <Fragment>
      <CSSTransition
        classNames="uhc-sticky"
        in={shouldShowStickyHeader}
        mountOnEnter={false}
        unmountOnExit={false}
        timeout={300}
      >
        <PlanDetailStickyHeader
          data={planData}
          isSaved={isSaved}
          onPlanSaveToggle={onPlanSaveToggle}
        />
      </CSSTransition>
      <PlanDetailHeader
        data={planData}
        isSaved={isSaved}
        savedDrugs={savedDrugs}
        showCompare={showCompare}
        showDrugPricingUnavailable={showDrugPricingUnavailable}
        showEnroll={showEnroll}
        showZipCodePrompt={showZipCodePrompt}
        onAddDrugsClick={onAddDrugsClick}
        onPlanSaveToggle={onPlanSaveToggle}
      />
      <PlanDetailBenefits data={planData} />
      <div className="container container-lg">
        <div className="row mb-40 mb-lg-60">
          <div className="column column-12">
            <UtilityBar
              isAuthenticated={isAuthenticated}
              savedDoctors={savedDoctors}
              savedDrugs={savedDrugs}
              savedPlans={savedPlans}
              onAddDoctorsClick={onAddDoctorsClick}
              onAddDrugsClick={onAddDrugsClick}
            />
          </div>
        </div>
      </div>
      <PlanDetailCoverage
        data={planData}
        isSaved={isSaved}
        savedDoctors={savedDoctors}
        savedDrugs={savedDrugs}
        showCompare={showCompare}
        showDentists={showDentists}
        showDrugPricingUnavailable={showDrugPricingUnavailable}
        onAddDoctorsClick={onAddDoctorsClick}
        onAddDrugsClick={onAddDrugsClick}
        onPlanSaveToggle={onPlanSaveToggle}
      />
      <PlanRecommendationsSection />
      <MedicareAdvantagePlansSection />
      <HelpSection />
      <CompareFooter planCount={1} isShown={showCompare} />
    </Fragment>
  );
};

export default PlanDetail;
