import { Button, LinkButton, Menu, Tag } from '@bswing/uhc-core';
import classNames from 'classnames';
import PlanCard from 'components/plan-card/plan-card';
import PrintAndEmail from 'components/print-and-email/print-and-email';
import Tabs from 'components/tabs/tabs';
import tabsData from 'components/tabs/tabs-data.js';
import UtilityBar from 'components/utility-bar/utility-bar';
import HelpSection from 'partials/help-section/help-section';
import MedicareAdvantagePlansSection from 'partials/medicare-advantage-plans-section/medicare-advantage-plans-section';
import PlanRecommendationsSection from 'partials/plan-recommendations-section/plan-recommendations-section';
import { Fragment } from 'react';
import Pluralize from 'react-pluralize';
import PlanListFilters from './plan-list-filters/plan-list-filters';
import PlanListHeader from './plan-list-header/plan-list-header';

const PlanList = (props) => {
  const {
    availablePlans = [],
    isAuthenticated = false,
    savedDoctors = [],
    savedDrugs = [],
    savedPlans = [],
    showAppliedFilters,
    showPlanToggle,
    showPlanYearDescription,
    version = 'MAPD',
    onAddDoctorsClick = () => {},
    onAddDrugsClick = () => {},
    onRemoveDoctorClick = () => {},
    onRemoveDrugClick = () => {},
    onPlanSaveToggle = () => {}
  } = props;

  return (
    <Fragment>
      <div className="container container-lg d-none d-lg-block">
        <div className="row my-20 my-lg-30">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row items-center justify-content-between">
              <div className="d-flex items-center gap-5">
                <img
                  src="/assets/icons/map-pin@2x.png"
                  alt=""
                  style={{ height: 21, width: 21 }}
                />
                <span>
                  Plans for{' '}
                  <LinkButton>
                    <span className="d-flex align-items-center gap-5">
                      <span>59102 Yellowstone County</span>
                      <img
                        src="/assets/icons/pencil-copy@2x.png"
                        alt=""
                        style={{ height: 12, width: 12 }}
                      />
                    </span>
                  </LinkButton>
                </span>
              </div>
              <div className="d-none d-lg-block">
                <PrintAndEmail emailLabel="Email" printLabel="Print" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tabs tabs={tabsData} />
      <PlanListHeader
        showPlanToggle={showPlanToggle}
        showPlanYearDescription={showPlanYearDescription}
      />
      <div className="container container-lg">
        <div className="row mb-20 mb-lg-30">
          <div className="column column-12">
            <UtilityBar
              isAuthenticated={isAuthenticated}
              savedDoctors={savedDoctors}
              savedDrugs={savedDrugs}
              savedPlans={savedPlans}
              onAddDoctorsClick={onAddDoctorsClick}
              onAddDrugsClick={onAddDrugsClick}
            />
          </div>
        </div>
        <div className="row">
          <div
            className={classNames(
              'column',
              version === 'PDP' ? 'd-none' : 'column-3 d-none d-lg-block'
            )}
          >
            <PlanListFilters version={version} />
          </div>
          <div className="column column-12 column-lg-9">
            <div className="d-flex d-lg-none align-items-center gap-20 mb-20">
              <Button isOutlined={true} isSecondary={true}>
                Filter Plans
              </Button>
              <LinkButton>Clear All</LinkButton>
            </div>
            {showAppliedFilters && version !== 'PDP' && (
              <div className="mb-20">
                <div className="d-flex align-items-center gap-15">
                  <div className="d-none d-lg-block text-bold">
                    Applied Filters:
                  </div>
                  <Tag isDismissable={true}>
                    Nationwide network + out-of-network access
                  </Tag>
                  <LinkButton className="d-none d-lg-block">
                    Clear All
                  </LinkButton>
                </div>
              </div>
            )}
            <div className="d-flex align-items-center justify-content-between gap-20 gap-lg-30 mb-20">
              <div>
                Showing{' '}
                <span className="text-bold">
                  <Pluralize singular={'plan'} count={availablePlans.length} />
                </span>
              </div>
              <Menu
                isBorderless={true}
                items={[
                  'Featured',
                  'Lowest premium',
                  'Highest premium',
                  'Lowest out-of-pocket maximum',
                  'Highest out-of-pocket maximum'
                ]}
                label={
                  <div>
                    <span className="text-bold">Sort:</span> Featured
                  </div>
                }
                position="right"
                onItemSelect={() => {}}
              />
            </div>
            <div className="d-flex flex-column gap-30">
              {availablePlans.map((plan, index) => (
                <PlanCard
                  cardNumber={index + 1}
                  cardTotal={availablePlans.length}
                  key={index}
                  isExpanded={true}
                  isSaved={!!savedPlans.find((p) => p.id === plan.id)}
                  planData={plan}
                  savedDoctors={savedDoctors}
                  savedDrugs={savedDrugs}
                  onAddDoctorsClick={onAddDoctorsClick}
                  onAddDrugsClick={onAddDrugsClick}
                  onRemoveDoctorClick={onRemoveDoctorClick}
                  onRemoveDrugClick={onRemoveDrugClick}
                  onSave={onPlanSaveToggle}
                />
              ))}
            </div>
            <div className="d-flex justify-content-center d-lg-none py-30">
              <PrintAndEmail emailLabel="Email" printLabel="Print" />
            </div>
          </div>
        </div>
      </div>
      <PlanRecommendationsSection />
      <MedicareAdvantagePlansSection />
      <HelpSection />
    </Fragment>
  );
};

export default PlanList;
