import { LinkButton, Toggle } from '@bswing/uhc-core';

const PlanListHeader = (props) => {
  const { showPlanToggle, showPlanYearDescription } = props;

  return (
    <div className="container container-lg">
      <div className="row py-20 py-lg-30">
        <div className="column column-12">
          <div className="d-lg-none mb-20">
            <LinkButton>
              <img
                className="mr-10"
                src="/assets/icons/chevron-left-icon@2x.png"
                alt=""
                style={{ height: 12, width: 8, verticalAlign: -1 }}
              />
              Back to plan results
            </LinkButton>
          </div>
          <div className="heading-1">
            Medicare Advantage Plans available for 2022
          </div>
          {showPlanToggle && (
            <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-20 mt-20">
              <Toggle
                options={['View 2022 Plans', 'View 2023 Plans']}
                style={{ minWidth: 320 }}
                onChange={() => {}}
              />
              {showPlanYearDescription && (
                <div>
                  Plans Starting in{' '}
                  <span className="text-bold">January 2022 or later.</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanListHeader;
