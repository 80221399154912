import { LinkButton, Toggle } from '@bswing/uhc-core';
import EmptyState from 'components/empty-state/empty-state';
import MSPlanCard from 'components/ms-plan-card/ms-plan-card';
import PlanCard from 'components/plan-card/plan-card';
import TabNavigation from 'components/tab-navigation/tab-navigation';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ShopperProfileFAQs from '../components/faqs';
import ShopperProfileFindPlanCTA from '../components/find-plan-cta';
import ShopperProfileHelpCTA from '../components/help-cta';
import ShopperProfileMoreOptionsCTA from '../components/more-options-cta';
import { savedPlansFaqData } from '../data/faq-data';
import { mockNavTabs } from '../data/nav-data';

const ShopperProfileSavedPlansPage = ({
  savedDoctors = [],
  savedDrugs = [],
  savedPlans = [],
  showPlanYearToggle,
  onAddDoctorsClick = () => {},
  onAddDrugsClick = () => {},
  onPlanSaveToggle = () => {}
}) => {
  const tabs = mockNavTabs.map((tab) => {
    if (tab.id === 'saved-plans') {
      return {
        ...tab,
        current: true
      };
    }
    return tab;
  });

  return (
    <Fragment>
      <div className="d-none d-lg-block mb-lg-30">
        <TabNavigation tabs={tabs} />
      </div>
      <div className="mb-30">
        <h2 className="mb-10">Your saved plans</h2>
        <p>
          This is your list of plans to compare based on what's most important
          to you. You can add and remove plans as needed.
        </p>
      </div>
      {showPlanYearToggle && (
        <div className="mb-30">
          <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-20">
            <Toggle
              options={['View 2023 Plans', 'View 2024 Plans']}
              style={{ minWidth: 320 }}
              onChange={() => {}}
            />
            <div>Plans Starting in January 2023 or later</div>
          </div>
        </div>
      )}
      {/* Populated State */}
      {savedPlans.length > 0 && (
        <Fragment>
          {/* MA Plans */}
          {savedPlans.filter((x) => x.type === 'MA').length > 0 && (
            <div className="mb-40">
              <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-10 gap-lg-20 mb-20">
                <h3 className="heading-3 text-gray-8">
                  Medicare Advantage plans (
                  {savedPlans.filter((x) => x.type === 'MA').length})
                </h3>
                <LinkButton>Compare plans</LinkButton>
              </div>
              <div className="d-flex flex-column gap-30">
                {savedPlans
                  .filter((x) => x.type === 'MA')
                  .map((plan, index) => (
                    <PlanCard
                      cardNumber={index + 1}
                      cardTotal={
                        savedPlans.filter((x) => x.type === 'MA').length
                      }
                      key={index}
                      isSaved={true}
                      planData={plan}
                      showCompare={true}
                      showCompareCheckbox={false}
                      showRemove={true}
                      savedDoctors={savedDoctors}
                      savedDrugs={savedDrugs}
                      onAddDoctorsClick={onAddDoctorsClick}
                      onAddDrugsClick={onAddDrugsClick}
                      onRemove={(event) => onPlanSaveToggle(event, false, plan)}
                      onSave={onPlanSaveToggle}
                    />
                  ))}
              </div>
            </div>
          )}
          {/* MS Plans */}
          {savedPlans.filter((x) => x.type === 'MS').length > 0 && (
            <div className="mb-40">
              <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-10 gap-lg-20 mb-20">
                <h3 className="heading-3 text-gray-8">
                  Medicare Supplement Plans (
                  {savedPlans.filter((x) => x.type === 'MS').length})
                </h3>
                <LinkButton>Compare plans</LinkButton>
              </div>
              <div className="d-flex flex-column gap-30">
                {savedPlans
                  .filter((x) => x.type === 'MS')
                  .map((plan, index) => (
                    <MSPlanCard
                      key={index}
                      isSaved={true}
                      planData={plan}
                      showCompare={true}
                      showCompareCheckbox={false}
                      showRemove={true}
                      showRestrictions={false}
                      onRemove={(event) => onPlanSaveToggle(event, false, plan)}
                      onSave={onPlanSaveToggle}
                    />
                  ))}
              </div>
            </div>
          )}
          <ShopperProfileMoreOptionsCTA />
        </Fragment>
      )}

      {/* Empty State */}
      {savedPlans.length === 0 && (
        <Fragment>
          <div className="mb-30">
            <EmptyState>
              <svg
                className="d-block mb-10"
                width="48"
                height="49"
                viewBox="0 0 48 49"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden={true}
                focusable={false}
              >
                <circle cx="24" cy="24.5" r="24" fill="white" />
                <path
                  d="M36.9057 15.1968C35.4266 13.7177 33.4205 12.8867 31.3287 12.8867C29.2369 12.8867 27.2308 13.7177 25.7517 15.1968L12.8467 28.1028L23.9997 39.2518L36.8997 26.3518C39.9816 23.2731 39.9843 18.2789 36.9057 15.1968Z"
                  fill="#002677"
                />
                <path
                  d="M22.2472 15.1967C20.7683 13.7177 18.7623 12.8867 16.6707 12.8867C14.5791 12.8867 12.5731 13.7177 11.0942 15.1967C9.61512 16.6757 8.78418 18.6816 8.78418 20.7732C8.78418 22.8649 9.61512 24.8708 11.0942 26.3497L12.8472 28.1027L24.0002 16.9497L22.2472 15.1967Z"
                  fill="#00BED5"
                />
              </svg>
              <p className="mb-20">
                Looks like you don't have any saved plans yet
              </p>
              <Link
                to="/health-plans/plan-summary"
                className="uhc-button uhc-button--secondary"
              >
                Shop all plans
              </Link>
            </EmptyState>
          </div>
          <ShopperProfileHelpCTA />
          <ShopperProfileFAQs faqs={savedPlansFaqData} />
          <ShopperProfileFindPlanCTA />
        </Fragment>
      )}
    </Fragment>
  );
};

export default ShopperProfileSavedPlansPage;
