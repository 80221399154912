import { Button } from '@bswing/uhc-core';

const ShopperProfilePreCTA = () => {
  return (
    <div className="mb-30">
      <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center gap-20">
        <img
          className="d-block d-lg-none"
          src="/assets/images/selecting-list-items-illustration@2x.png"
          alt=""
          style={{ height: 80, width: 'auto' }}
        />
        <div className="flex">
          <h2 className="heading-2 mb-20">Get a plan recommendation</h2>
          <p className="mb-20">
            Answer a few simple questions and get a personalized list of Medicare and Medigap plans, ranked by what's important to you.
          </p>
          <ul className="m-0 p-0 mb-30 list-style-none">
            <li className="d-flex align-items-center gap-10 mb-15">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden={true}
                focusable={false}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.50013 13.4749L4.02513 9.99987L2.8418 11.1749L7.50013 15.8332L17.5001 5.8332L16.3251 4.6582L7.50013 13.4749Z"
                  fill="#002677"
                />
              </svg>
              <span>Find plans that fit your priorities</span>
            </li>
            <li className="d-flex align-items-center gap-10 mb-15">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden={true}
                focusable={false}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.50013 13.4749L4.02513 9.99987L2.8418 11.1749L7.50013 15.8332L17.5001 5.8332L16.3251 4.6582L7.50013 13.4749Z"
                  fill="#002677"
                />
              </svg>
              <span>Estimate your drug costs</span>
            </li>
            <li className="d-flex align-items-center gap-10 mb-15">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden={true}
                focusable={false}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.50013 13.4749L4.02513 9.99987L2.8418 11.1749L7.50013 15.8332L17.5001 5.8332L16.3251 4.6582L7.50013 13.4749Z"
                  fill="#002677"
                />
              </svg>
              <span>See if your doctors are covered</span>
            </li>
          </ul>
          <Button isOutlined={true} isSecondary={true}>
            Get started
          </Button>
        </div>
        <div className="d-none d-lg-flex align-items-center justify-content-center flex">
          <img
            src="/assets/images/selecting-list-items-illustration@2x.png"
            alt=""
            style={{ height: 230, width: 225 }}
          />
        </div>
      </div>
    </div>
  );
};

export default ShopperProfilePreCTA;
