import { Button, Checkbox, LinkButton } from '@bswing/uhc-core';
import classNames from 'classnames';
import FlyoutPanel from 'components/flyout-panel/flyout-panel';
import UtilityTag from 'components/utility-tag/utility-tag';
import useOnClickOutside from 'hooks/useOnClickOutside';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import Pluralize from 'react-pluralize';
import { Link } from 'react-router-dom';
import './plan-card.scss';

const PlanCard = (props) => {
  const {
    version,
    cardNumber,
    cardTotal,
    compareCount,
    className,
    planData,
    isExpandable,
    isExpanded: isDefaultExpanded,
    isSaved,
    savedDoctors,
    savedDrugs,
    showCompare,
    showCompareCheckbox,
    showEnroll,
    showFiveStar,
    showRemove,
    onAddDoctorsClick,
    onAddDrugsClick,
    onRemoveDoctorClick,
    onRemoveDrugClick,
    onCompare,
    onRemove,
    onSave,
    ...rest
  } = props;

  const doctorsMenuButtonRef = useRef(null);
  const drugsMenuButtonRef = useRef(null);

  const [isDoctorsMenuActive, setIsDoctorsMenuActive] = useState(false);
  const [isDrugsMenuActive, setIsDrugsMenuActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);

  const componentClassName = classNames(
    'uhc-plan-card',
    {
      'uhc-plan-card--expandable': isExpandable,
      'uhc-plan-card--expanded': isExpanded
    },
    className
  );

  const handleMenuAddDoctorsClick = () => {
    setIsDoctorsMenuActive(false);
    onAddDoctorsClick();
  };

  const handleMenuRemoveDoctorClick = (doctorId) => {
    setIsDoctorsMenuActive(false);
    onRemoveDoctorClick(doctorId);
  };

  const handleMenuAddDrugsClick = () => {
    setIsDrugsMenuActive(false);
    onAddDrugsClick();
  };

  const handleMenuRemoveDrugClick = (drugId) => {
    setIsDrugsMenuActive(false);
    onRemoveDrugClick(drugId);
  };

  const handleOnCompareChange = (value, planName) => {
    return (event) => {
      onCompare(event, value, planName);
    };
  };

  const handleOnRemove = (planName) => {
    return (event) => {
      onRemove(event, planName);
    };
  };

  const handleOnSave = (value, plan) => {
    return (event) => {
      onSave(event, value, plan);
    };
  };

  useOnClickOutside(doctorsMenuButtonRef, () => setIsDoctorsMenuActive(false));
  useOnClickOutside(drugsMenuButtonRef, () => setIsDrugsMenuActive(false));

  return (
    <div className={componentClassName} {...rest}>
      <div className="uhc-plan-card__header">
        <div className="d-flex align-items-center justify-content-between mb-5">
          <div className="text-small">
            {cardNumber} of {cardTotal} Plans
          </div>
          <div className="d-flex align-items-center gap-20">
            {showFiveStar && (
              <div className="d-none d-lg-block">
                <div className="d-flex align-items-center gap-10">
                  <div className="d-flex gap-5">
                    {[...Array(5)].map((e, i) => (
                      <img
                        src="/assets/icons/star@2x.png"
                        className=""
                        key={i}
                        alt="star"
                        style={{ width: 21, height: 19 }}
                      />
                    ))}
                  </div>
                  <div className="text-small text-bold text-white">
                    CMS Rating
                  </div>
                </div>
              </div>
            )}
            {showRemove && (
              <div>
                <LinkButton
                  className="text-white text-small"
                  onClick={handleOnRemove(planData?.name)}
                >
                  <span className="d-flex align-items-center gap-10">
                    <span className="d-none d-lg-block">Remove</span>
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden={true}
                      focusable={false}
                    >
                      <path
                        d="M12.75 13.25L4.25 4.75"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.75 4.75L4.25 13.25"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </LinkButton>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row align-items-lg-end justify-content-lg-between">
          <div className="heading-3 text-white mb-5 mb-lg-0">
            <span className="d-flex align-items-center gap-10">
              {planData?.name}{' '}
              <LinkButton>
                <img
                  className="d-none d-lg-block"
                  src="/assets/icons/full-icon-library-all-24-px-action-ic-info-outline-2@2x.png"
                  alt="info icon"
                  style={{ width: 24, height: 24 }}
                />
              </LinkButton>
            </span>
          </div>
          <div className="d-flex align-items-center gap-5">
            Plan Year {planData?.planCard?.planYear}{' '}
            <LinkButton>
              <img
                className="d-block"
                src="/assets/icons/full-icon-library-all-24-px-action-ic-info-outline@2x.png"
                alt="info icon"
                style={{ width: 18, height: 18 }}
              />
              <span className="visually-hidden">Plan year info</span>
            </LinkButton>
          </div>
        </div>
      </div>
      <div className="uhc-plan-card__main">
        <div className="d-flex align-items-start gap-40 mb-10 mb-lg-20">
          {planData.monthlyPremium && (
            <div className="flex-1">
              <div>Monthly premium</div>
              <div className="heading-2 text-dark">
                <span
                  dangerouslySetInnerHTML={{ __html: planData.monthlyPremium }}
                />
              </div>
            </div>
          )}

          {planData.outOfPocketMax && (
            <div className="d-none d-lg-block flex-1">
              <div>Out-of-pocket maximum </div>
              <div className="heading-2 text-dark">
                <span
                  dangerouslySetInnerHTML={{
                    __html: planData.outOfPocketMax
                  }}
                />
              </div>
            </div>
          )}

          <div className="d-none d-lg-block flex-1">
            <div>Estimated annual drug cost</div>
            {savedDrugs.length > 0 && (
              <div className="heading-2 text-dark">
                <span
                  dangerouslySetInnerHTML={{
                    __html: planData.annualDrugCost
                  }}
                />
              </div>
            )}
            {savedDrugs.length === 0 && (
              <LinkButton className="mt-0 mt-lg-10" onClick={onAddDrugsClick}>
                <span className="d-flex align-items-center gap-10">
                  <img
                    className="d-block"
                    src="/assets/icons/circle-add-icon@2x.png"
                    alt=""
                    style={{ height: 20, width: 20 }}
                  />
                  <span>
                    Add<span className="d-none d-lg-inline"> your drugs</span>
                  </span>
                </span>
              </LinkButton>
            )}
          </div>

          <div className="d-none d-lg-block flex-1">
            <div>Doctors covered</div>
            {savedDoctors.length > 0 && (
              <div className="heading-2 text-dark">
                {savedDoctors.length} of {savedDoctors.length}{' '}
                <LinkButton className="underline" onClick={onAddDoctorsClick}>
                  Edit
                </LinkButton>
              </div>
            )}
            {savedDoctors.length === 0 && (
              <LinkButton className="mt-0 mt-lg-10" onClick={onAddDoctorsClick}>
                <span className="d-flex align-items-center gap-10">
                  <img
                    className="d-block"
                    src="/assets/icons/circle-add-icon@2x.png"
                    alt=""
                    style={{ height: 20, width: 20 }}
                  />
                  <span>
                    Add<span className="d-none d-lg-inline"> your doctors</span>
                  </span>
                </span>
              </LinkButton>
            )}
          </div>
          <Button className="d-block d-lg-none">Enroll</Button>
        </div>
        {showFiveStar && (
          <div className="d-block d-lg-none mb-20">
            <div className="d-flex align-items-center gap-10">
              <div className="d-flex gap-5">
                {[...Array(5)].map((e, i) => (
                  <img
                    src="/assets/icons/star@2x.png"
                    className=""
                    key={i}
                    alt="star"
                    style={{ width: 21, height: 19 }}
                  />
                ))}
              </div>
              <div className="text-small text-bold text-blue-secondary">
                CMS Rating
              </div>
            </div>
          </div>
        )}
        <div className="d-none d-lg-block mb-20">
          <p>{planData?.planCard?.summary}</p>
        </div>
        <div className="row row-collapse">
          <div className="column column-12 column-lg-7">
            <div className="border border-rounded-sm">
              <table>
                <tbody>
                  <tr className="d-flex d-lg-none justify-content-between p-10">
                    <td className="p-0 border-none">Out-of-Pocket Maximum</td>
                    <td className="p-0 border-none text-bold">$7,550</td>
                  </tr>
                  <tr className="d-flex d-lg-none justify-content-between border-top p-10">
                    <td className="p-0 border-none">Est. Annual Drug Cost</td>
                    {savedDrugs.length > 0 && (
                      <td className="p-0 border-none text-bold">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: planData?.annualDrugCost
                          }}
                        />
                      </td>
                    )}
                    {savedDrugs.length === 0 && (
                      <td className="p-0 border-none text-bold">
                        <a href="#link">Add drugs</a>
                      </td>
                    )}
                  </tr>
                  {/* START FROM DATA */}
                  {planData?.planCard?.lineItems &&
                    planData?.planCard?.lineItems.map((lineItem, index) => (
                      <tr
                        className={
                          'd-flex justify-content-between p-10 border-top' +
                          (index === 0 ? ' border-lg-none' : '')
                        }
                        key={index}
                      >
                        <td className="p-0 border-none">{lineItem.label}</td>
                        <td className="p-0 border-none text-bold text-right">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: lineItem.value
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {(planData?.planCard?.hasDoctorsMenu ||
              planData?.planCard?.hasDrugsMenu) && (
              <div className="d-flex flex-column gap-10 mt-20">
                {planData?.planCard?.hasDoctorsMenu &&
                  savedDoctors.length === 0 && (
                    <UtilityTag as="button" onClick={onAddDoctorsClick}>
                      <img
                        className="d-block mr-10"
                        src="/assets/icons/circle-add-icon@2x.png"
                        alt=""
                        style={{ height: 20, width: 20 }}
                      />
                      <span>
                        <span className="text-blue-secondary text-bold">
                          Add your doctors
                        </span>{' '}
                        to see if they are in network
                      </span>
                    </UtilityTag>
                  )}
                {planData?.planCard?.hasDoctorsMenu &&
                  savedDoctors.length > 0 && (
                    <div
                      className="position-relative"
                      ref={doctorsMenuButtonRef}
                    >
                      <UtilityTag
                        className="width-100"
                        as="button"
                        onClick={() =>
                          setIsDoctorsMenuActive(!isDoctorsMenuActive)
                        }
                      >
                        <img
                          className="d-block mr-10"
                          src="/assets/icons/check-circle@2x.png"
                          alt=""
                          style={{ height: 20, width: 20 }}
                        />
                        <span className="d-flex align-items-center justify-content-between flex-1">
                          <span>
                            <span className="text-bold">Your doctors</span>
                            <span className="ml-5">
                              {savedDoctors.length} of {savedDoctors.length}{' '}
                              covered
                            </span>
                          </span>
                          <img
                            className="d-block mr-10"
                            src="/assets/icons/chevron-down-icon@2x.png"
                            alt=""
                            style={{
                              width: 12,
                              height: 8,
                              transform: isDoctorsMenuActive
                                ? 'scaleY(-1)'
                                : 'none'
                            }}
                          />
                        </span>
                      </UtilityTag>
                      <FlyoutPanel
                        aria-expanded={isDoctorsMenuActive}
                        aria-hidden={!isDoctorsMenuActive}
                        style={{ marginTop: 4 }}
                      >
                        {savedDoctors.map((doctor, index) => (
                          <div
                            className={`p-15${
                              index % 2 !== 0 ? ' bg-gray-2' : ''
                            }`}
                            key={index}
                          >
                            <div className="d-flex flex-column flex-lg-row align-items-start gap-10 gap-lg-40">
                              <div className="flex">
                                <div className="text-bold mb-5">
                                  {doctor.name}
                                </div>
                                <div>{doctor.address}</div>
                              </div>
                              <div>
                                <img
                                  className="mr-5"
                                  src="/assets/icons/check-icon-alt@2x.png"
                                  alt="Covered"
                                  style={{ width: 17, height: 11 }}
                                />
                                <span>In-Network</span>
                              </div>
                            </div>
                            <div className="mt-10">
                              <LinkButton
                                className="underline text-small"
                                onClick={() =>
                                  handleMenuRemoveDoctorClick(doctor.id)
                                }
                              >
                                Remove
                              </LinkButton>
                            </div>
                          </div>
                        ))}
                        <div className="p-15">
                          <LinkButton
                            className="d-block"
                            onClick={handleMenuAddDoctorsClick}
                          >
                            <span className="d-flex align-items-center">
                              <img
                                className="d-block mr-5"
                                src="/assets/icons/add-box@2x.png"
                                alt=""
                                style={{ height: 24, width: 25 }}
                              />
                              <span>Add doctors &amp; dentists</span>
                            </span>
                          </LinkButton>
                        </div>
                      </FlyoutPanel>
                    </div>
                  )}
                {planData?.planCard?.hasDrugsMenu &&
                  savedDrugs.length === 0 && (
                    <UtilityTag as="button" onClick={onAddDrugsClick}>
                      <img
                        className="d-block mr-10"
                        src="/assets/icons/circle-add-icon@2x.png"
                        alt=""
                        style={{ height: 20, width: 20 }}
                      />
                      <span>
                        <span className="text-blue-secondary text-bold">
                          Add your drugs
                        </span>{' '}
                        to see estimated drug costs
                      </span>
                    </UtilityTag>
                  )}
                {planData?.planCard?.hasDrugsMenu && savedDrugs.length > 0 && (
                  <div className="position-relative" ref={drugsMenuButtonRef}>
                    <UtilityTag
                      className="width-100"
                      as="button"
                      onClick={() => setIsDrugsMenuActive(!isDrugsMenuActive)}
                    >
                      <img
                        className="d-block mr-10"
                        src="/assets/icons/check-circle@2x.png"
                        alt=""
                        style={{ height: 20, width: 20 }}
                      />
                      <span className="d-flex align-items-center justify-content-between flex-1">
                        <span>
                          <span className="text-bold">
                            <Pluralize
                              singular={'Drug'}
                              count={savedDrugs.length}
                            />{' '}
                            added
                          </span>
                          <span className="ml-5">
                            {savedDrugs.length} of {savedDrugs.length} covered
                          </span>
                        </span>
                        <img
                          className="d-block mr-10"
                          src="/assets/icons/chevron-down-icon@2x.png"
                          alt=""
                          style={{
                            width: 12,
                            height: 8,
                            transform: isDrugsMenuActive ? 'scaleY(-1)' : 'none'
                          }}
                        />
                      </span>
                    </UtilityTag>
                    <FlyoutPanel
                      aria-expanded={isDrugsMenuActive}
                      aria-hidden={!isDrugsMenuActive}
                      style={{ marginTop: 4 }}
                    >
                      {savedDrugs.map((drug, index) => (
                        <div
                          className={`p-15${
                            index % 2 !== 0 ? ' bg-gray-2' : ''
                          }`}
                          key={index}
                        >
                          <div className="d-flex flex-column flex-lg-row align-items-start gap-10 gap-lg-40">
                            <div className="flex">
                              <div className="text-bold mb-5">{drug.name}</div>
                              <div>{drug.dosage}</div>
                            </div>
                            <div>
                              <img
                                className="mr-5"
                                src="/assets/icons/check-icon-alt@2x.png"
                                alt="Covered"
                                style={{ width: 17, height: 11 }}
                              />
                              <span>Covered</span>
                            </div>
                          </div>
                          <div className="mt-10">
                            <LinkButton
                              className="underline text-small"
                              onClick={() => handleMenuRemoveDrugClick(drug.id)}
                            >
                              Remove
                            </LinkButton>
                          </div>
                        </div>
                      ))}
                      <div className="p-15">
                        <LinkButton
                          className="d-block"
                          onClick={handleMenuAddDrugsClick}
                        >
                          View drug summary
                        </LinkButton>
                      </div>
                    </FlyoutPanel>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="column column-12 column-lg-5 mt-20 mt-lg-0">
            <div className="text-bold mb-20">Additional Benefits</div>
            <ul className="m-0 p-0">
              {planData?.planCard?.benefits &&
                planData?.planCard?.benefits.map((benefit, index) => (
                  <li className="d-flex align-items-start mb-15" key={index}>
                    <div className="mr-10">
                      <img
                        src="/assets/icons/check-icon-alt@2x.png"
                        alt=""
                        style={{ width: 17, height: 11 }}
                      />
                    </div>
                    <div>{benefit}</div>
                  </li>
                ))}
            </ul>
            <div className="d-flex flex-column gap-15">
              <div>
                <LinkButton className="text-small">
                  <span className="d-flex align-items-center gap-5">
                    Check eligibility
                    <img
                      src="/assets/icons/chevron-right-icon@2x.png"
                      alt=""
                      style={{ width: 8, height: 12 }}
                    />
                  </span>
                </LinkButton>
              </div>
              <div>
                <LinkButton className="text-small">
                  <span className="d-flex align-items-center gap-5">
                    Do you qualify for Extra Help?
                    <img
                      src="/assets/icons/chevron-right-icon@2x.png"
                      alt=""
                      style={{ width: 8, height: 12 }}
                    />
                  </span>
                </LinkButton>
              </div>
            </div>
            <div className="mt-30">
              Optional rider: <span className="text-bold">Dental Platinum</span>
              <br />
              (add $40 to your monthly premium){' '}
              <a href="#link">
                <img
                  src="/assets/icons/info-icon@2x.png"
                  alt="info icon"
                  style={{ width: 24, height: 24, verticalAlign: 'middle' }}
                />
                <span className="visually-hidden">Rider info</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="uhc-plan-card__footer">
        <div className="uhc-plan-card__footer-inner">
          {isExpandable && (
            <button
              className="uhc-plan-card__expand-button"
              onClick={() => setIsExpanded(!isExpanded)}
              aria-hidden={true}
              tabIndex={-1}
            >
              {isExpanded ? 'Show less' : 'Show more'}
              <img
                className="ml-10"
                src="/assets/icons/chevron-down-icon@2x.png"
                alt=""
                style={{
                  width: 12,
                  height: 8,
                  transform: isExpanded ? 'scaleY(-1)' : 'none'
                }}
              />
            </button>
          )}
          <div className="d-flex flex-column flex-lg-row align-items-center">
            {showEnroll && (
              <Button className="d-none d-lg-block width-100 width-lg-auto mb-20 mb-lg-0 mr-lg-20">
                Enroll in plan
              </Button>
            )}
            <div className="d-flex flex-column flex-lg-row align-items-lg-center width-100">
              <div className="d-flex flex-row-reverse flex-lg-row align-items-center mb-20 mb-lg-0 width-100">
                <LinkButton
                  as={Link}
                  to={`/health-plans/details/${planData?.id}`}
                >
                  <span className="d-flex align-items-center gap-5">
                    View plan details
                    <img
                      src="/assets/icons/chevron-right-icon@2x.png"
                      alt=""
                      style={{ width: 8, height: 12 }}
                    />
                  </span>
                </LinkButton>
                <span className="flex"></span>
                <Button
                  className="mr-20"
                  isOutlined={true}
                  isSecondary={true}
                  onClick={handleOnSave(!isSaved, planData)}
                >
                  <span className="d-flex align-items-center">
                    {isSaved && (
                      <>
                        <img
                          className="d-block mr-10 ml-n5"
                          src="/assets/icons/heart-icon-small-filled@2x.png"
                          alt=""
                          style={{
                            height: 16,
                            width: 16
                          }}
                        />
                        <span>Saved</span>
                      </>
                    )}
                    {!isSaved && (
                      <>
                        <img
                          className="d-block mr-10 ml-n5"
                          src="/assets/icons/heart-icon-small@2x.png"
                          alt=""
                          style={{
                            height: 16,
                            width: 16
                          }}
                        />
                        <span>Save</span>
                      </>
                    )}
                  </span>
                </Button>
              </div>
              <div className="d-flex align-items-center no-wrap">
                {showCompareCheckbox && (
                  <Checkbox
                    checked={showCompare}
                    label={
                      showCompare ? (
                        <span className="visually-hidden">Add to compare</span>
                      ) : (
                        'Add to compare'
                      )
                    }
                    onChange={handleOnCompareChange(
                      !showCompare,
                      planData?.name
                    )}
                  />
                )}
                {showCompare && (
                  <LinkButton>
                    <span className="d-flex align-items-center">
                      <span>Compare Plans</span>
                      <img
                        className="ml-10"
                        src="/assets/icons/chevron-right-icon@2x.png"
                        alt=""
                        style={{ width: 8, height: 12 }}
                      />
                    </span>
                  </LinkButton>
                )}
              </div>
            </div>
          </div>
          {!showEnroll && (
            <div className="border-top mt-20 pt-20">
              <div className="text-bold text-blue-primary mb-10">
                Enroll by phone: 1-877-659-9664 (TTY 711)
              </div>
              <div>
                Call a UnitedHealthcare sales agent to enroll, 7 a.m. - 7 p.m.
                CT, 7 days a week.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PlanCard.propTypes = {
  version: PropTypes.oneOf(['MAPD', 'PDP', 'SNP']),
  cardNumber: PropTypes.number,
  cardTotal: PropTypes.number,
  className: PropTypes.string,
  compareCount: PropTypes.number,
  isExpandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isSaved: PropTypes.bool,
  planData: PropTypes.object,
  savedDoctors: PropTypes.array,
  savedDrugs: PropTypes.array,
  showCompare: PropTypes.bool,
  showCompareCheckbox: PropTypes.bool,
  showEnroll: PropTypes.bool,
  showFiveStar: PropTypes.bool,
  showRemove: PropTypes.bool,
  onAddDoctorsClick: PropTypes.func,
  onAddDrugsClick: PropTypes.func,
  onRemoveDoctorClick: PropTypes.func,
  onRemoveDrugClick: PropTypes.func,
  onCompare: PropTypes.func,
  onRemove: PropTypes.func,
  onSave: PropTypes.func
};

PlanCard.defaultProps = {
  version: 'MAPD',
  cardNumber: 0,
  cardTotal: 0,
  isExpandable: true,
  isExpanded: false,
  isSaved: false,
  planData: {},
  savedDoctors: [],
  savedDrugs: [],
  showCompare: false,
  showCompareCheckbox: true,
  showEnroll: true,
  showFiveStar: false,
  showRemove: false,
  onAddDoctorsClick: () => {},
  onAddDrugsClick: () => {},
  onRemoveDoctorClick: () => {},
  onRemoveDrugClick: () => {},
  onCompare: () => {},
  onRemove: () => {},
  onSave: () => {}
};

export default PlanCard;
