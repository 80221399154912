const MSHomeHeadline = ({ headline, overline }) => {
  return (
    <div className="container container-lg">
      <div className="row py-60 pt-lg-130 pb-lg-100">
        <div className="column column-12 column-lg-8 column-lg-shift-2 text-center">
          {overline && <div className="text-large mb-10">{overline}</div>}
          {headline && <h3 className="display-large">{headline}</h3>}
        </div>
      </div>
    </div>
  );
};

export default MSHomeHeadline;
