import { Button } from '@bswing/uhc-core';
import RecommendedPlanCard from 'components/recommended-plan-card/recommended-plan-card';
import TabNavigation from 'components/tab-navigation/tab-navigation';
import { Fragment } from 'react';
import ShopperProfileFAQs from '../components/faqs';
import ShopperProfileHelpCTA from '../components/help-cta';
import ShopperProfilePreCTA from '../components/pre-cta';
import { recommendedPlansFaqData } from '../data/faq-data';
import { mockNavTabs } from '../data/nav-data';

const ShopperProfileRecommendedPlansPage = ({ data }) => {
  const tabs = mockNavTabs.map((tab) => {
    if (tab.id === 'recommended-plans') {
      return {
        ...tab,
        current: true
      };
    }
    return tab;
  });

  return (
    <Fragment>
      <div className="d-none d-lg-block mb-lg-30">
        <TabNavigation tabs={tabs} />
      </div>

      {/* Populated State */}
      {data && (
        <Fragment>
          <div className="mb-30">
            <h2 className="mb-10">Your recommended plans</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              ligula neque, porta eget aliquet a, ultricies fringilla elit.
              Suspendisse ac vehicula tortor.
            </p>
          </div>
          <div className="mb-30">
            <div className="d-flex flex-column flex-lg-row flex-lg-wrap align-items-lg-center gap-30 gap-lg-40">
              <div className="flex">
                <RecommendedPlanCard data={data} />
              </div>
              <div className="flex">
                <img
                  className="d-none d-lg-block mb-lg-10"
                  src="/assets/images/ic_Star_2C_onBlue@2x.png"
                  alt=""
                  style={{ width: 39, height: 39 }}
                />
                <h3 className="heading-2 mb-10">
                  More recommended plans available
                </h3>
                <p className="mb-20">
                  Since you have completed the plan recommendation questions, we
                  have 5 ranked results for you based on your specific needs.
                </p>
                <Button isOutlined={true} isSecondary={true}>
                  View all of your results
                </Button>
              </div>
            </div>
          </div>
        </Fragment>
      )}

      {/* Empty State */}
      {!data && <ShopperProfilePreCTA />}

      <ShopperProfileHelpCTA />
      <ShopperProfileFAQs faqs={recommendedPlansFaqData} />
    </Fragment>
  );
};

export default ShopperProfileRecommendedPlansPage;
