import { Button } from '@bswing/uhc-core';
import { withBreakpoints } from 'react-breakpoints';

const MSPlanDetailRxSection = (props) => {
  const { currentBreakpoint } = props;

  return (
    <div className="container container-lg py-40 py-lg-100">
      <div className="row">
        <div className="column column-12 column-lg-10 column-lg-shift-1">
          <div className="d-flex flex-column-reverse flex-lg-row align-items-center">
            <div className="flex-auto">
              <h2 className="text-large text-sans-serif text-bold text-blue-primary mb-10">
                Get prescription drug coverage
              </h2>
              <h3 className="heading-2 text-dark mb-20">
                Add an AARP® MedicareRx Part D Plan for complete prescription
                drug coverage
              </h3>
              <p className="text-large">
                Prescription drugs can be expensive. Pairing an AARP® MedicareRx
                Part D Plan from UnitedHealthcare with a Medicare Supplement
                plan can help protect you from unexpected medicare and
                prescription drug costs now or in the future.
              </p>
              <ul className="d-none d-lg-flex flex-column gap-10 text-large">
                <li>Low monthly premiums</li>
                <li>$0 copay for Tier 1 drugs</li>
                <li>
                  Medication home delivery copays as low as $0 from OptumRx®
                </li>
                <li>$0 deductible</li>
                <li>Stable, predictable insulin copays at $35</li>
                <li>Coverage for thousands of brand name generic drugs</li>
              </ul>
              <div className="mt-30">
                <Button isSecondary={true} isOutlined={true}>
                  <span className="d-flex align-items-center gap-5">
                    <span>View plans</span>
                    <svg
                      className="mr-n5"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        d="m10 6.157-1.41 1.41 4.58 4.59-4.58 4.59 1.41 1.41 6-6z"
                        fill="currentColor"
                        fillRule="evenodd"
                      />
                    </svg>
                  </span>
                </Button>
              </div>
            </div>
            <div className="ml-lg-60 mb-30 mb-lg-0 flex-shrink-0">
              <img
                className="d-block height-auto"
                src="/assets/images/asset-3@2x.png"
                style={
                  currentBreakpoint === 'mobile'
                    ? { width: '100%', maxWidth: '100%' }
                    : { width: 472, maxWidth: '100%' }
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withBreakpoints(MSPlanDetailRxSection);
