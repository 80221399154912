const faqData = [
  {
    question: 'What is Medicare Supplement and how much coverage do I need?',
    answer:
      'Medicare Supplement insurance plans (also known as Medigap plans) are offered by private insurance companies to help pay some of the out of pocket costs that Original Medicare (which includes Part A and Part B) doesn’t pay. Some plans pay most of your out of pocket costs, but have a higher monthly premium. Other plans pay fewer out of pocket costs, but have a lower monthly premium. Benefits and costs vary depending on the plan chosen.'
  },
  {
    question:
      'Does a Medicare Supplement insurance plan replace Original Medicare Part A and Part B?',
    answer:
      'No. A Medicare Supplement insurance plan works with Original Medicare, which includes Part A and Part B. It helps cover some of the out of pocket health care costs that Original Medicare doesn’t pay for. For instance, Medicare Part B generally covers about 80% of Part B expenses. You’re responsible for paying the rest. A Medicare Supplement insurance plan could help pay your share.'
  },
  {
    question: 'When can I apply for Medicare Supplement Insurance?',
    answer: (
      <div>
        <div className="mb-20">
          You can apply for a Medicare Supplement insurance plan any time during
          the year.* The best time to enroll is during your Open Enrollment
          period. The Medicare Supplement Open Enrollment period is the
          six-month period that starts the first day of the month in which you
          are age 65 or older and enrolled in Medicare Part B. Some states may
          have additional open enrollment periods, including those for people
          under 65, and there may be other situations in which your acceptance
          may be guaranteed.
        </div>
        <div>
          *If you choose to apply outside of your Medicare Supplement Open
          Enrollment period or a Guaranteed Issue period, you may be
          underwritten and not accepted into the plan.
        </div>
      </div>
    )
  },
  {
    question: 'What if I need drug coverage?',
    answer:
      'You can enroll in a Medicare Supplement Insurance plan and a Part D plan. Medicare Part D is prescription drug coverage. Medicare Part D plans (PDP) help pay for medications prescribed by a doctor. Part D plans are offered by private insurance companies with a Medicare contract. All prescription drug plans must meet the same basic guidelines created by the federal government. However, each Part D plan has its own drug list or formulary. When choosing a Part D plan, you need to make sure it covers the drugs you take.'
  }
];

export default faqData;
