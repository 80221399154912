import { Alert, Button } from '@bswing/uhc-core';

const CTAContainer = ({ children }) => (
  <Alert>
    <div className="d-flex flex-column flex-lg-row align-items-stretch align-items-lg-center justify-content-lg-between flex gap-20 gap-lg-30">
      {children}
    </div>
  </Alert>
);

const MSPlanListCTA = ({ index }) => {
  if (index === 1) {
    return (
      <CTAContainer>
        <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-10">
          <img
            className="d-none d-lg-block"
            src="/assets/images/full-brand-icon-library-miscellaneous-ic-star-1-c-on-blue@2x.png"
            alt=""
            style={{ height: 32, width: 32 }}
          />
          <div className="text-bold">More Coverage. More Control.</div>
          <div>With new plans and lower rates.</div>
        </div>
      </CTAContainer>
    );
  }

  if (index === 3) {
    return (
      <CTAContainer>
        <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-10">
          <img
            className="d-none d-lg-block"
            src="/assets/images/full-brand-icon-library-communication-ic-phone-1-c-on-blue@2x.png"
            alt=""
            style={{ height: 32, width: 32 }}
          />
          <div className="text-bold">Need Help?</div>
          <div>
            Contact UnitedHealthcare: 7AM-11PM ET, Monday-Friday, 9AM-5PM ET,
            Saturday
          </div>
          <div>
            <span className="text-bold">1-888-301-3127</span> (TTY 711)
          </div>
        </div>
      </CTAContainer>
    );
  }

  if (index === 5) {
    return (
      <CTAContainer>
        <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-10">
          <img
            className="d-none d-lg-block"
            src="/assets/images/full-brand-icon-library-data-icon-doc-mult-1-c-cir-rgb@2x.png"
            alt=""
            style={{ height: 32, width: 32 }}
          />
          <div className="text-bold">Compare Plans</div>
          <div>
            See a detailed comparison of your costs across all plans in your
            area.
          </div>
        </div>
        <Button isOutlined={true} isSecondary={true}>
          Compare
        </Button>
      </CTAContainer>
    );
  }

  return null;
};

export default MSPlanListCTA;
