import { LinkButton } from '@bswing/uhc-core';
import classNames from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './collapse-menu.scss';

const CollapseMenuItem = ({ item, onClick }) => {
  const componentClassName = classNames('uhc-collapse-menu__item', {
    'uhc-collapse-menu__item--active': item.isActive
  });

  return (
    <li className={componentClassName}>
      <Link
        className="uhc-collapse-menu__link"
        to={item.href}
        onClick={onClick}
      >
        {item.icon && (
          <span className="uhc-collapse-menu__item-icon">{item.icon}</span>
        )}
        <span className="uhc-collapse-menu__item-label">{item.label}</span>
      </Link>
    </li>
  );
};

const CollapseMenu = ({
  className,
  id,
  navData,
  showPlanRecommendations = false,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const activeItem = navData.find((x) => x.isActive);
  const componentClassName = classNames(
    'uhc-collapse-menu',
    {
      'uhc-collapse-menu--expanded': isExpanded,
      'uhc-collapse-menu--recommendations': showPlanRecommendations
    },
    className
  );

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  if (!activeItem) {
    return null;
  }

  return (
    <div className={componentClassName} id={`${id}-group`} {...rest}>
      <button
        type="button"
        aria-expanded={isExpanded}
        className="uhc-collapse-menu__trigger"
        aria-controls={id}
        id={`${id}-trigger`}
        onClick={handleClick}
      >
        <span className="uhc-collapse-menu__title">
          <span className="uhc-collapse-menu__title-icon">
            {activeItem.icon}
          </span>
          <span>{activeItem.label}</span>
        </span>
        <svg
          className="uhc-collapse-menu__trigger-icon"
          width="12"
          height="9"
          viewBox="0 0 12 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden={true}
          focusable={false}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.41 0.839844L6 5.41984L10.59 0.839844L12 2.24984L6 8.24984L0 2.24984L1.41 0.839844Z"
            fill="currentColor"
          />
        </svg>
      </button>
      <div
        id={id}
        hidden={!isExpanded}
        role="region"
        aria-labelledby={`${id}-trigger`}
        className="uhc-collapse-menu__panel"
      >
        <ul className="uhc-collapse-menu__list">
          {navData.map((item, index) => (
            <CollapseMenuItem item={item} key={index} onClick={handleClick} />
          ))}
        </ul>

        {showPlanRecommendations && (
          <div className="border border-rounded-sm mx-15 p-15">
            <div className="d-flex align-items-center gap-10">
              <img
                src="/assets/icons/gold-star@2x.png"
                alt=""
                style={{ width: 20, height: 20 }}
              />
              <LinkButton href="#link">Plan Recommendations</LinkButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CollapseMenu;
