import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './side-nav.scss';

const SideNavItem = ({ item }) => {
  const itemClassName = classNames('uhc-side-nav__item', {
    'uhc-side-nav__item--active': item.isActive
  });

  return (
    <li className={itemClassName}>
      <Link to={item.href}>
        {item.icon && <span className="uhc-side-nav__icon">{item.icon}</span>}
        <span className="uhc-side-nav__label">{item.label}</span>
      </Link>
    </li>
  );
};

const SideNav = (props) => {
  const { items } = props;

  return (
    <ul className="uhc-side-nav">
      {items.map((item, index) => (
        <SideNavItem item={item} key={index} />
      ))}
    </ul>
  );
};

export default SideNav;
