import { LinkButton } from '@bswing/uhc-core';
import Tabs from 'components/tabs/tabs';
import tabsData from 'components/tabs/tabs-data.js';
import LearnAboutPlansSection from 'partials/learn-about-plans-section/learn-about-plans-section';
import NotSureWhereToStartSection from 'partials/not-sure-where-to-start-section/not-sure-where-to-start-section';
import { Fragment } from 'react';

const Portfolio = () => {
  return (
    <Fragment>
      <div className="container container-lg my-20 my-lg-30">
        <div className="row my-20 my-lg-30">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row items-center justify-content-between">
              <div className="d-flex items-center gap-5">
                <img
                  src="/assets/icons/map-pin@2x.png"
                  alt=""
                  style={{ height: 21, width: 21 }}
                />
                <span>
                  Plans for{' '}
                  <LinkButton>
                    <span className="d-flex align-items-center gap-5">
                      <span>59102 Yellowstone County</span>
                      <img
                        src="/assets/icons/pencil-copy@2x.png"
                        alt=""
                        style={{ height: 12, width: 12 }}
                      />
                    </span>
                  </LinkButton>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tabs tabs={tabsData} />
      <div className="py-30 py-lg-60 text-center">
        <div className="container container-lg">
          <div className="row">
            <div className="column column-12 column-lg-6 column-lg-shift-3">
              <div className="heading-1">
                Select an option above to see available plans in your area
              </div>
            </div>
          </div>
        </div>
      </div>
      <LearnAboutPlansSection />
      <NotSureWhereToStartSection />
    </Fragment>
  );
};

export default Portfolio;
