import classNames from 'classnames';
import './utility-tag.scss';

const UtilityTag = ({ as, children, className, ...rest }) => {
  const componentClassName = classNames('uhc-utility-tag', {}, className);
  const Component = as ? as : 'div';

  return (
    <Component className={componentClassName} {...rest}>
      {children}
    </Component>
  );
};

export default UtilityTag;
