import ApplicationCard from 'components/application-card/application-card';
import { Fragment } from 'react';
import ShopperProfileDashboardTiles from '../components/dashboard-tiles';
import ShopperProfileFAQs from '../components/faqs';
import ShopperProfileFindPlanCTA from '../components/find-plan-cta';
import ShopperProfileHelpCTA from '../components/help-cta';
import ShopperProfilePreCTA from '../components/pre-cta';
import { applicationsFaqData } from '../data/faq-data';

const ShopperProfileDashboardPage = ({
  data = [],
  savedDoctors = [],
  savedDrugs = [],
  savedPlans = [],
  onAddDoctorsClick = () => {},
  onAddDrugsClick = () => {}
}) => {
  return (
    <Fragment>
      {/* Populated State */}
      {data.length > 0 && (
        <Fragment>
          <div className="mb-30">
            <h2 className="mb-10">Your applications</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              ligula neque, porta eget aliquet a, ultricies fringilla elit.
              Suspendisse ac vehicula tortor.
            </p>
          </div>
          <div className="mb-30">
            <div className="d-flex flex-column gap-30">
              {data.map((application, index) => (
                <ApplicationCard data={application} key={index} />
              ))}
            </div>
          </div>
        </Fragment>
      )}

      {/* Empty State */}
      {data.length === 0 && <ShopperProfilePreCTA />}

      {/* <ShopperProfileDashboardTiles
        savedDoctors={savedDoctors}
        savedDrugs={savedDrugs}
        savedPlans={savedPlans}
        onAddDoctorsClick={onAddDoctorsClick}
        onAddDrugsClick={onAddDrugsClick}
      /> */}
      <ShopperProfileHelpCTA />
      <ShopperProfileFindPlanCTA />
      <ShopperProfileFAQs faqs={applicationsFaqData} />
    </Fragment>
  );
};

export default ShopperProfileDashboardPage;
