import CompareFooter from 'components/compare-footer/compare-footer';
import useScrollPosition from 'hooks/useScrollPosition';
import MSPlanListCallout from 'pages/ms-plan-list/ms-plan-list-callout/ms-plan-list-callout';
import MSPlanListDisclaimers from 'pages/ms-plan-list/ms-plan-list-disclaimers/ms-plan-list-disclaimers';
import { CSSTransition } from 'react-transition-group';
import MSPlanDetailBenefits from './ms-plan-detail-benefits/ms-plan-detail-benefits';
import MSPlanDetailCoverage from './ms-plan-detail-coverage/ms-plan-detail-coverage';
import MSPlanDetailHeader from './ms-plan-detail-header/ms-plan-detail-header';
import MSPlanDetailImportantInformation from './ms-plan-detail-important-information/ms-plan-detail-important-information';
import MSPlanDetailPlansSection from './ms-plan-detail-plans-section/ms-plan-detail-plans-section';
import MSPlanDetailRxSection from './ms-plan-detail-rx-section/ms-plan-detail-rx-section';
import MSPlanDetailStickyHeader from './ms-plan-detail-sticky-header/ms-plan-detail-sticky-header';

const MSPlanDetail = (props) => {
  const {
    isSaved = false,
    planData,
    savedDoctors = [],
    showCompare,
    showInformationAdded,
    onAddDoctorsClick,
    onPlanSaveToggle = () => {}
  } = props;
  const scrollPosition = useScrollPosition();

  const shouldShowStickyHeader = scrollPosition > 500;

  return (
    <>
      <CSSTransition
        classNames="uhc-sticky"
        in={shouldShowStickyHeader}
        mountOnEnter={false}
        unmountOnExit={false}
        timeout={300}
      >
        <MSPlanDetailStickyHeader
          data={planData}
          isSaved={isSaved}
          onPlanSaveToggle={onPlanSaveToggle}
        />
      </CSSTransition>
      <MSPlanDetailHeader
        data={planData}
        isSaved={isSaved}
        showCompare={showCompare}
        showInformationAdded={showInformationAdded}
        onPlanSaveToggle={onPlanSaveToggle}
      />
      <MSPlanDetailBenefits data={planData} />
      <MSPlanDetailCoverage
        data={planData}
        isSaved={isSaved}
        savedDoctors={savedDoctors}
        showCompare={showCompare}
        onAddDoctorsClick={onAddDoctorsClick}
        onPlanSaveToggle={onPlanSaveToggle}
      />
      <MSPlanDetailImportantInformation />
      <MSPlanDetailPlansSection />
      <MSPlanDetailRxSection />
      <MSPlanListCallout />
      <div className="mt-40">
        <MSPlanListDisclaimers />
      </div>
      <CompareFooter planCount={1} isShown={showCompare} />
    </>
  );
};

export default MSPlanDetail;
