import { Button } from '@bswing/uhc-core';
import classNames from 'classnames';
import './application-card.scss';

const ApplicationCard = ({
  className,
  data,
  onContinue = () => {},
  ...rest
}) => {
  const componentClassName = classNames('uhc-application-card', {}, className);

  return (
    <div className={componentClassName} {...rest}>
      <div className="uhc-application-card__header">
        <h3 className="heading-3 text-white mb-10">
          <span>{data?.name}</span>
        </h3>
        <div className="text-bold">
          <span>{data?.description}</span>
        </div>
      </div>
      <div className="uhc-application-card__main">
        <div className="d-none d-lg-block row row-collapse">
          <div className="column column-12">
            <div className="d-flex align-items-start gap-40 mb-20 pb-20 border-bottom">
              {data?.type === 'MA' && (
                <div className="flex-1">
                  <div>Monthly premium</div>
                  <div className="heading-2 text-blue-primary">
                    <span className="uhc-sup">$</span>
                    {data?.monthlyPremium}
                  </div>
                </div>
              )}
              {data?.type === 'MA' && (
                <div className="flex-1">
                  <div>Out-of-pocket maximum </div>
                  <div className="heading-2 text-blue-primary">
                    <span className="uhc-sup">$</span>
                    {data?.outOfPocketMax}
                  </div>
                </div>
              )}
              <div className="flex-1">
                <div>ZIP Code</div>
                <div className="heading-2 text-blue-primary">
                  {data?.zipCode}
                </div>
              </div>
              {data?.type === 'MA' && (
                <div className="flex-1">
                  <div>Plan year</div>
                  <div className="heading-2 text-blue-primary">
                    {data?.planYear}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-block d-lg-none row row-collapse mb-20">
          <div className="column column-12">
            <div className="border border-rounded-sm">
              <div className="d-flex justify-content-between p-10">
                <div className="p-0 border-none">Monthly premium</div>
                <div className="p-0 border-none text-bold">
                  ${data?.monthlyPremium}
                </div>
              </div>
              <div className="d-flex justify-content-between border-top p-10">
                <div className="p-0 border-none">Out-of-pocket maximum</div>
                <div className="p-0 border-none text-bold">
                  ${data?.outOfPocketMax}
                </div>
              </div>
              <div className="d-flex justify-content-between border-top p-10">
                <div className="p-0 border-none">ZIP Code</div>
                <div className="p-0 border-none text-bold">{data?.zipCode}</div>
              </div>
              <div className="d-flex justify-content-between border-top p-10">
                <div className="p-0 border-none">Plan Year</div>
                <div className="p-0 border-none text-bold">
                  {data?.planYear}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row row-collapse">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row gap-20 gap-lg-40">
              <div>
                <span className="text-bold">Status:</span> {data?.status}
              </div>
              {data?.status === 'In-Progress' && (
                <div>
                  <span className="text-bold">Last updated:</span>{' '}
                  {data?.lastUpdated}
                </div>
              )}
              {(data?.status === 'Pending' || data?.status === 'Submitted') && (
                <div>
                  <span className="text-bold">Submitted:</span>{' '}
                  {data?.lastUpdated}
                </div>
              )}
            </div>
            {data?.status === 'In-Progress' && (
              <div className="mt-20">
                <Button onClick={(event) => onContinue(event, data?.id)}>
                  Continue your application
                </Button>
              </div>
            )}
            {data?.status === 'Pending' && (
              <div className="mt-20">
                <Button
                  isOutlined={true}
                  isSecondary={true}
                  onClick={(event) => onContinue(event, data?.id)}
                >
                  View application details
                </Button>
              </div>
            )}
          </div>
        </div>
        {data?.type === 'MS' && (
          <div className="bg-gray-2 mt-20 mx-n20 mb-n20 p-20">
            UnitedHealthcare Insurance Company of America (UnitedHealthcare)
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicationCard;
