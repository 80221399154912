import { Button, LinkButton, Modal } from '@bswing/uhc-core';

const RemoveDoctorModal = ({
  data = {},
  isShown = false,
  onConfirm = () => {},
  onClose = () => {}
}) => {
  return (
    <Modal
      className="uhc-accent"
      isFullscreen={false}
      isShown={isShown}
      title={<div>&nbsp;</div>}
      onCloseComplete={onClose}
      onOpenComplete={() => {}}
      aria-label="Are you sure you want to remove this doctor?"
      style={{ width: 570 }}
    >
      <div className="px-20 pb-40 px-lg-80 pb-lg-60">
        <div className="d-flex flex-column align-items-center gap-40">
          <h2 className="text-center">
            Are you sure you want to remove this doctor?
          </h2>
          <div className="border border-rounded-sm width-100">
            <div className="d-flex align-items-start p-20">
              <img
                className="d-block mr-20"
                src="/assets/images/stethoscope@2x.png"
                alt=""
                style={{
                  width: 48,
                  height: 48
                }}
              />
              <div className="flex-1">
                <div className="text-bold mb-5">{data?.name}</div>
                <div className="mb-5">{data?.speciality}</div>
                <div>{data?.address}</div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center gap-30">
            <Button isSecondary={true} onClick={onConfirm}>
              Remove from saved doctors
            </Button>
            <LinkButton onClick={onClose}>Keep doctor</LinkButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveDoctorModal;
