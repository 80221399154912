import { Checkbox, LinkButton } from '@bswing/uhc-core';
import classNames from 'classnames';
import { useState } from 'react';
import filterGroupArrowIcon from './filter-group-arrow-icon@2x.png';
import './filter-group.scss';

const FilterGroup = (props) => {
  const {
    children,
    className,
    contentPrompt,
    defaultExpanded = false,
    filters,
    header,
    id,
    layout = 'desktop',
    onChange = () => {},
    ...rest
  } = props;
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const buttonId = `${id}-button`;
  const contentId = `${id}-content`;

  const componentClassName = classNames(
    'uhc-filter-group',
    {
      'uhc-filter-group--expanded': isExpanded
    },
    className
  );

  const handleClick = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      setIsExpanded((prev) => !prev);
    }
  };

  if (layout === 'mobile') {
    return (
      <fieldset className={componentClassName} {...rest}>
        <legend className="text-bold">{header}</legend>
        <div className="my-20">
          {contentPrompt && (
            <div className="mb-20">
              <LinkButton
                className="text-small"
                onClick={handleClick}
                onKeyUp={handleKeyUp}
              >
                <span className="d-flex align-items-center gap-10">
                  <span>{contentPrompt}</span>
                  <img
                    className="d-block"
                    src="/assets/icons/full-icon-library-all-24-px-hardware-ic-keyboard-arrow-down@2x.png"
                    alt=""
                    style={{
                      height: 17,
                      width: 17,
                      transform: isExpanded ? 'scaleY(-1)' : 'scaleY(1)'
                    }}
                  />
                </span>
              </LinkButton>
              {children && isExpanded && (
                <div className="mt-15">{children}</div>
              )}
            </div>
          )}
          <div className="d-flex flex-column gap-15">
            {filters?.map((filter) => (
              <Checkbox
                key={filter.label}
                label={<span className="text-regular">{filter.label}</span>}
                checked={filter.isChecked}
                onChange={() => onChange(filter)}
              />
            ))}
          </div>
        </div>
      </fieldset>
    );
  }

  return (
    <fieldset className={componentClassName} {...rest}>
      <div
        className="uhc-filter-group__header"
        id={buttonId}
        role="button"
        tabIndex={0}
        aria-controls={contentId}
        aria-disabled="false"
        aria-expanded={isExpanded}
        onClick={handleClick}
        onKeyUp={handleKeyUp}
      >
        <legend className="text-bold">{header}</legend>
        <img
          className="uhc-filter-group__arrow"
          src={filterGroupArrowIcon}
          alt=""
        />
      </div>
      <div
        className="uhc-filter-group__content"
        hidden={!isExpanded}
        id={contentId}
        role="region"
        aria-labelledby={buttonId}
      >
        <LinkButton className="text-small mb-20">{contentPrompt}</LinkButton>
        <div className="d-flex flex-column gap-15">
          {filters.map((filter) => (
            <Checkbox
              key={filter.label}
              label={<span className="text-regular">{filter.label}</span>}
              checked={filter.isChecked}
              onChange={() => onChange(filter)}
            />
          ))}
        </div>
      </div>
    </fieldset>
  );
};

export default FilterGroup;
