import { LinkButton } from '@bswing/uhc-core';
import { Fragment } from 'react';

const AddLinkButton = ({ onClick }) => (
  <LinkButton className="text-lg-large d-block" onClick={onClick}>
    <span className="d-flex align-items-center">
      <img
        className="d-block mr-5"
        src="/assets/icons/add-box@2x.png"
        alt=""
        style={{ height: 24, width: 25 }}
      />
      <span>
        Add your prescription drugs
        <span className="d-none d-lg-inline"> to see estimated costs</span>
      </span>
    </span>
  </LinkButton>
);

const CoverageDrugsTable = ({
  savedDrugs = [],
  showDrugPricingUnavailable,
  onAddDrugsClick = () => {}
}) => {
  if (savedDrugs.length > 0) {
    return (
      <Fragment>
        {showDrugPricingUnavailable && (
          <div className="bg-teal-quaternary d-flex align-items-start align-items-lg-center border-rounded-sm p-20">
            <img
              className="d-block mr-15"
              src="/assets/icons/alert-icon@2x.png"
              alt=""
              style={{ width: 24, height: 24 }}
            />
            <p>
              REMEDI SENIORCARE provides Prescription Drug Coverage for this
              plan. For drug pricing at this Pharmacy, call UnitedHealthcare:
              1-877-699-5710 (TTY 711). <LinkButton>Change Pharmacy</LinkButton>
            </p>
          </div>
        )}
        {!showDrugPricingUnavailable && (
          <div className="d-flex align-items-center">
            <img
              className="d-block mr-10"
              src="/assets/icons/map-pin@2x.png"
              alt=""
              style={{ width: 21, height: 21 }}
            />
            <span className="text-lg-large">
              Pharmacy: Walgreens 333 7th Ave
            </span>{' '}
            <LinkButton className="ml-20 underline">Edit</LinkButton>
          </div>
        )}
        <div className="border border-rounded-sm overflow-hidden">
          <table style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr className="bg-gray-2">
                <th
                  className="text-bold text-blue-primary p-20"
                  style={{ width: '35%' }}
                >
                  <span>Your drugs</span>
                  <LinkButton
                    className="ml-5 underline"
                    onClick={onAddDrugsClick}
                  >
                    Edit
                  </LinkButton>
                  <LinkButton
                    className="d-inline-block d-lg-none"
                    style={{ float: 'right' }}
                  >
                    View drug cost details
                  </LinkButton>
                </th>
                <th
                  className="text-bold text-blue-primary p-20 d-none d-lg-table-cell"
                  style={{ width: 'auto' }}
                >
                  <span>What you'll pay</span>{' '}
                  <LinkButton className="ml-5">
                    View drug cost details
                  </LinkButton>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-20 valign-top" style={{ width: '35%' }}>
                  <span className="text-bold text-lg-large">
                    Estimated Annual Drug Cost
                  </span>
                  <span className="d-block d-lg-none mt-10">
                    You Pay: $7,317.84
                  </span>
                </td>
                <td
                  className="p-20 valign-top d-none d-lg-table-cell"
                  style={{ width: 'auto' }}
                >
                  <span className="text-lg-large">$7,317.84</span>
                </td>
              </tr>

              {savedDrugs.map((drug, index) => (
                <tr className={index % 2 === 0 ? 'bg-gray-2' : ''} key={index}>
                  <td className="p-20 valign-top" style={{ width: '35%' }}>
                    <div className="d-flex align-items-start">
                      <div className="mr-10">
                        <img
                          src="/assets/icons/pill-icon@2x.png"
                          alt=""
                          style={{ width: 24, height: 24 }}
                        />
                      </div>
                      <div>
                        <div className="text-bold text-lg-large">
                          {drug.name}
                        </div>
                        <div className="d-block d-lg-none">
                          <div className="my-5">
                            {drug.covered ? (
                              <img
                                src="/assets/icons/check-icon-alt@2x.png"
                                alt="Covered"
                                style={{ width: 17, height: 11 }}
                              />
                            ) : (
                              <img
                                src="/assets/icons/not-covered-x@2x.png"
                                alt="Not Covered"
                                style={{ width: 17, height: 13 }}
                              />
                            )}
                            <span>
                              {drug.covered ? 'Covered' : 'Not Covered'}
                            </span>
                          </div>
                          <div className="text-small">
                            You Pay: $9 copay (Initial Coverage Stage - Tier 1)
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    className="p-20 valign-top d-none d-lg-table-cell"
                    style={{ width: 'auto' }}
                  >
                    <div className="mb-5">
                      <img
                        className="mr-10"
                        src="/assets/icons/check-icon-alt@2x.png"
                        alt="Covered"
                        style={{ width: 17, height: 11 }}
                      />
                      <span className="text-lg-large">Covered</span>
                    </div>
                    <div className="text-small">
                      $9 copay (Initial Coverage Stage - Tier 1)
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }

  return (
    <div className="border border-rounded-sm overflow-hidden">
      <table style={{ tableLayout: 'fixed' }}>
        <tbody>
          <tr>
            <td className="p-20" style={{ width: '35%' }}>
              <span className="text-bold text-lg-large">Your drugs</span>
              <div className="d-block d-lg-none mt-10">
                <AddLinkButton onClick={onAddDrugsClick} />
              </div>
            </td>
            <td
              className="p-20 d-none d-lg-table-cell"
              style={{ width: 'auto' }}
            >
              <AddLinkButton onClick={onAddDrugsClick} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CoverageDrugsTable;
