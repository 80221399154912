import { Button, LinkButton } from '@bswing/uhc-core';
import classNames from 'classnames';
import Badge from 'components/badge/badge';
import useOnClickOutside from 'hooks/useOnClickOutside';
import SavedItemsMenu from 'pages/shopper-profile/partials/saved-items-menu';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './header.scss';

const HeaderSkipLink = () => (
  <a className="uhc-header__skip-link" href="#main-content">
    Skip to main content
  </a>
);

const HeaderTop = () => {
  return (
    <div className="uhc-header__top">
      <div className="container container-lg">
        <div className="row">
          <div className="column column-12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-30">
                <img
                  src="/assets/images/uhc-wordmark@2x.png"
                  alt="UnitedHealthcare wordmark"
                  style={{ height: 12, width: 'auto' }}
                />
                <nav>
                  <ul className="uhc-header__tabs">
                    <li className="uhc-header__tab uhc-header__tab--active">
                      <a href="#link">Medicare</a>
                    </li>
                    <li className="uhc-header__tab">
                      <a href="#link">Individuals &amp; Families</a>
                    </li>
                    <li className="uhc-header__tab">
                      <a href="#link">Medicaid</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <LinkButton className="text-blue-primary">
                <span className="d-flex align-items-center gap-10">
                  <img
                    className="d-block"
                    src="/assets/icons/account-icon@2x.png"
                    alt=""
                    style={{ height: 30, width: 30 }}
                  />
                  <span>Member sign in</span>
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden={true}
                    focusable={false}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.22419 0.425781L5.07979 4.27298L8.9354 0.425781L10.1198 1.61018L5.07979 6.65018L0.0397949 1.61018L1.22419 0.425781Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HeaderSearchForm = () => {
  return (
    <form className="uhc-header__search-form">
      <input
        type="search"
        aria-label="Search"
        aria-required="true"
        placeholder="Search"
      />
      <button>
        <img
          className="d-block"
          src="/assets/icons/search-icon@2x.png"
          alt="search icon"
          style={{ height: 24, width: 24 }}
        />
        <span>Search</span>
      </button>
    </form>
  );
};

const HeaderMain = ({ savedPlans = [] }) => {
  return (
    <div className="uhc-header__main">
      <div className="container container-lg position-static">
        <div className="row">
          <div className="column column-12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-15">
                <a href="#link" className="uhc-header__logo">
                  <img
                    className="d-block"
                    src="/assets/images/uhc-logo@2x.png"
                    alt="UnitedHealthcare logo"
                  />
                </a>
                <div className="uhc-header__logo-text">
                  UnitedHealthcare Insurance Company
                  <br /> or an affiliate (UnitedHealthcare)
                </div>
              </div>
              <div className="uhc-header__mobile">
                <ul className="uhc-header__mobile-nav">
                  <li className="position-relative">
                    <Link to="/account/dashboard">
                      <span className="d-flex align-items-center flex-column text-bold text-smaller text-blue-primary">
                        <img
                          className="d-block"
                          src="/assets/icons/heart-circle-icon@2x.png"
                          alt="heart circle icon"
                          style={{ height: 32, width: 32 }}
                        />
                        Account
                      </span>
                    </Link>
                    {savedPlans.length > 0 && (
                      <Badge>{savedPlans.length}</Badge>
                    )}
                  </li>
                  <li>
                    <a href="#link">
                      <span className="d-flex align-items-center flex-column text-bold text-smaller text-blue-primary">
                        <img
                          className="d-block"
                          src="/assets/icons/menu-icon@2x.png"
                          alt="menu icon"
                          style={{ height: 32, width: 32 }}
                        />
                        Menu
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="uhc-header__desktop gap-20">
                <LinkButton className="text-blue-primary mr-10">
                  <span className="d-flex align-items-center gap-5">
                    <img
                      src="/assets/icons/phone-blue-icon@2x.png"
                      alt=""
                      style={{ height: 16, width: 16 }}
                    />
                    <span>1-855-888-1640</span>
                  </span>
                </LinkButton>
                <Button isOutlined={true} isSecondary={true}>
                  Find a doctor
                </Button>
                <HeaderSearchForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MenuLinkButton = ({ children, ...rest }) => {
  return (
    <LinkButton className="text-white focus-teal" {...rest}>
      <span className="d-flex align-items-center gap-5">
        <span>{children}</span>
        <svg
          width="9"
          height="4"
          viewBox="0 0 9 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden={true}
          focusable={false}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.75 0L4.75 4L8.75 0H0.75Z"
            fill="#F9F9F9"
          />
        </svg>
      </span>
    </LinkButton>
  );
};

const HeaderBottom = ({ isAuthenticated, savedPlans = [] }) => {
  const location = useLocation();
  const savedItemsMenuButtonRef = useRef(null);

  const [isSavedItemsMenuActive, setIsSavedItemsMenuActive] = useState(false);
  useOnClickOutside(savedItemsMenuButtonRef, () =>
    setIsSavedItemsMenuActive(false)
  );

  useEffect(() => {
    setIsSavedItemsMenuActive(false);
  }, [location]);

  return (
    <div className="uhc-header__bottom">
      <div className="container container-lg position-static">
        <div className="row">
          <div className="column column-12">
            <div className="d-flex align-items-center justify-content-between">
              <LinkButton className="text-white focus-teal">
                Medicare
              </LinkButton>
              <div className="uhc-header__desktop gap-40">
                <nav aria-label="Main menu">
                  <ul className="d-flex align-items-center gap-40 list-style-none m-0 p-0">
                    <li>
                      <MenuLinkButton>Shop Medicare Plans</MenuLinkButton>
                    </li>
                    <li>
                      <MenuLinkButton>Learn about Medicare</MenuLinkButton>
                    </li>
                    <li>
                      <MenuLinkButton>Member Resources</MenuLinkButton>
                    </li>
                  </ul>
                </nav>
                <div
                  className="position-relative"
                  ref={savedItemsMenuButtonRef}
                >
                  <button
                    className="uhc-header__saved-items-button"
                    aria-expanded={isSavedItemsMenuActive}
                    aria-haspopup="true"
                    onClick={() =>
                      setIsSavedItemsMenuActive(!isSavedItemsMenuActive)
                    }
                  >
                    <span className="d-flex align-items-center gap-10">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.075 0C11.596 0 10.1765 0.662125 9.25 1.70845C8.3235 0.662125 6.904 0 5.425 0C2.807 0 0.75 1.9782 0.75 4.49591C0.75 7.58583 3.64 10.1035 8.0175 13.9292L9.25 15L10.4825 13.921C14.86 10.1035 17.75 7.58583 17.75 4.49591C17.75 1.9782 15.693 0 13.075 0ZM9.335 12.7112L9.25 12.7929L9.165 12.7112C5.119 9.18801 2.45 6.85831 2.45 4.49591C2.45 2.86104 3.725 1.63488 5.425 1.63488C6.734 1.63488 8.009 2.44414 8.4595 3.56403H10.049C10.491 2.44414 11.766 1.63488 13.075 1.63488C14.775 1.63488 16.05 2.86104 16.05 4.49591C16.05 6.85831 13.381 9.18801 9.335 12.7112Z"
                          fill="currentColor"
                        />
                      </svg>
                      <span>
                        {isAuthenticated
                          ? 'Your account'
                          : 'Your guest account'}
                      </span>
                    </span>
                  </button>
                  {savedPlans.length > 0 && <Badge>{savedPlans.length}</Badge>}
                  <SavedItemsMenu
                    isAuthenticated={isAuthenticated}
                    aria-expanded={isSavedItemsMenuActive}
                    aria-hidden={!isSavedItemsMenuActive}
                    style={{
                      transform: 'translate3d(75px, 5px, 0)',
                      width: 700
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = ({ className, isAuthenticated, savedPlans = [], ...rest }) => {
  const headerClassNames = classNames('uhc-header', {}, className);

  return (
    <header className={headerClassNames} {...rest}>
      <HeaderSkipLink />
      <HeaderTop />
      <HeaderMain savedPlans={savedPlans} />
      <HeaderBottom isAuthenticated={isAuthenticated} savedPlans={savedPlans} />
    </header>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
