import { Button } from '@bswing/uhc-core';
import ApplicationCard from 'components/application-card/application-card';
import TabNavigation from 'components/tab-navigation/tab-navigation';
import { Fragment } from 'react';
import ShopperProfileFAQs from '../components/faqs';
import { applicationsFaqData } from '../data/faq-data';
import { mockNavTabs } from '../data/nav-data';

const ShopperProfileApplicationsPage = ({ data = [] }) => {
  const tabs = mockNavTabs.map((tab) => {
    if (tab.id === 'applications') {
      return {
        ...tab,
        current: true
      };
    }
    return tab;
  });

  return (
    <Fragment>
      <div className="d-none d-lg-block mb-lg-30">
        <TabNavigation tabs={tabs} />
      </div>
      <div className="mb-30">
        <h2 className="mb-10">Your applications</h2>
        <p>
          Review your list of applications. If you are still working on
          completing your application, it will appear here. Once you have
          submitted your application, you will also be able to track its status.
        </p>
      </div>

      {/* Populated State */}
      {data.length > 0 && (
        <div className="mb-30">
          <div className="d-flex flex-column gap-30">
            {data.map((application, index) => (
              <ApplicationCard data={application} key={index} />
            ))}
          </div>
        </div>
      )}

      {/* Empty State */}
      {data.length === 0 && (
        <div className="mb-30">
          <div className="d-flex flex-column flex-lg-row align-items-center gap-40">
            <div className="flex">
              <h2 className="heading-5 text-blue-primary mb-10">
                No applications in progress
              </h2>
              <p className="mb-20">
                Start browsing all plans available in your area. When you find
                the one that fits your needs and budget, you can begin the
                enrollment process. Licensed insurance agents are available if
                you have any questions. All information you provide throughout
                the secure application process will be kept confidential.
              </p>
              <Button isSecondary={true} isOutlined={true}>
                Browse all plans
              </Button>
            </div>
            <div className="d-none d-lg-flex align-items-center justify-content-center flex">
              <img
                src="/assets/images/roadsign-illustration@2x.png"
                alt=""
                style={{ height: 'auto', width: 230 }}
              />
            </div>
          </div>
        </div>
      )}
      <ShopperProfileFAQs faqs={applicationsFaqData} />
    </Fragment>
  );
};

export default ShopperProfileApplicationsPage;
