import { Button, Checkbox, LinkButton } from '@bswing/uhc-core';
import { Fragment } from 'react';

const CheckboxLabel = ({ showCompare }) => {
  if (showCompare) {
    return (
      <LinkButton onClick={(e) => e.preventDefault()}>
        <span className="d-flex align-items-center">
          Compare 1 Plan{' '}
          <img
            className="ml-10"
            src="/assets/icons/chevron-right-icon@2x.png"
            alt=""
            style={{ width: 8, height: 12 }}
          />
        </span>
      </LinkButton>
    );
  }

  return <span>Add to Compare</span>;
};

const MSPlanDetailActions = ({
  showCompare,
  isSaved,
  onPlanSaveToggle = () => {}
}) => {
  return (
    <div
      className="d-flex flex-column flex-lg-row flex-lg-wrap align-items-lg-center"
      style={{ rowGap: 20, columnGap: 40 }}
    >
      <div className="d-flex align-items-center">
        <Button style={{ maxWidth: '100%', width: 200 }}>
          Start Application
        </Button>
        <Button
          className="ml-10"
          isOutlined={true}
          isSecondary={true}
          onClick={onPlanSaveToggle}
        >
          <span className="d-flex align-items-center">
            {isSaved && (
              <Fragment>
                <img
                  className="d-block mr-10 ml-n5"
                  src="/assets/icons/heart-icon-small-filled@2x.png"
                  alt=""
                  style={{
                    height: 16,
                    width: 16
                  }}
                />
                <span>Saved</span>
              </Fragment>
            )}
            {!isSaved && (
              <Fragment>
                <img
                  className="d-block mr-10 ml-n5"
                  src="/assets/icons/heart-icon-small@2x.png"
                  alt=""
                  style={{
                    height: 16,
                    width: 16
                  }}
                />
                <span>Save</span>
              </Fragment>
            )}
          </span>
        </Button>
      </div>
      <Checkbox
        checked={showCompare}
        label={<CheckboxLabel showCompare={showCompare} />}
        onChange={() => {}}
      />
    </div>
  );
};

export default MSPlanDetailActions;
