import { LinkButton } from '@bswing/uhc-core';
import PropTypes from 'prop-types';

const PrintAndEmail = (props) => {
  const { emailLabel, printLabel } = props;

  return (
    <div className="d-flex align-items-center">
      <LinkButton>
        <span className="d-flex align-items-center gap-10">
          <img
            src="/assets/icons/email-icon@2x.png"
            alt=""
            style={{ height: 16, width: 20 }}
          />
          <span>{emailLabel}</span>
        </span>
      </LinkButton>
      <LinkButton className="ml-30">
        <span className="d-flex align-items-center gap-10">
          <img
            src="/assets/icons/print-icon@2x.png"
            alt=""
            style={{ height: 18, width: 20 }}
          />
          <span>{printLabel}</span>
        </span>
      </LinkButton>
    </div>
  );
};

PrintAndEmail.propTypes = {
  emailLabel: PropTypes.string,
  printLabel: PropTypes.string
};

PrintAndEmail.defaultProps = {
  emailLabel: 'Email',
  printLabel: 'Print'
};

export default PrintAndEmail;
