import { Button, LinkButton, Menu, Tag } from '@bswing/uhc-core';
import MSPlanCard from 'components/ms-plan-card/ms-plan-card';
import PrintAndEmail from 'components/print-and-email/print-and-email';
import Tabs from 'components/tabs/tabs';
import tabsData from 'components/tabs/tabs-data.js';
import UtilityBar from 'components/utility-bar/utility-bar';
import MSHomeHeadline from 'pages/ms-home/ms-home-headline/ms-home-headline';
import { Fragment } from 'react';
import Pluralize from 'react-pluralize';
import MSPlanListCallout from './ms-plan-list-callout/ms-plan-list-callout';
import MSPlanListCTA from './ms-plan-list-cta/ms-plan-list-cta';
import MSPlanListDisclaimers from './ms-plan-list-disclaimers/ms-plan-list-disclaimers';
import MSPlanListFAQs from './ms-plan-list-faqs/ms-plan-list-faqs';
import MSPlanListFilters from './ms-plan-list-filters/ms-plan-list-filters';
import MSPlanListHeader from './ms-plan-list-header/ms-plan-list-header';
import MSPlanListImportantInformation from './ms-plan-list-important-information/ms-plan-list-important-information';

const MSPlanList = ({
  availablePlans = [],
  isAuthenticated = false,
  savedDoctors = [],
  savedDrugs = [],
  savedPlans = [],
  showAppliedFilters,
  showInformationAdded,
  onAddDoctorsClick = () => {},
  onAddDrugsClick = () => {},
  onPlanSaveToggle = () => {}
}) => {
  // console.log(availablePlans);
  return (
    <Fragment>
      <div className="container container-lg">
        <div className="row my-20 my-lg-30">
          <div className="column column-12">
            <div className="d-flex flex-column flex-lg-row items-center justify-content-between">
              <div className="d-flex items-center gap-5">
                <img
                  src="/assets/icons/map-pin@2x.png"
                  alt=""
                  style={{ height: 21, width: 21 }}
                />
                <span>
                  Plans for{' '}
                  <LinkButton>
                    <span className="d-flex align-items-center gap-5">
                      <span>59102 Yellowstone County</span>
                      <img
                        src="/assets/icons/pencil-copy@2x.png"
                        alt=""
                        style={{ height: 12, width: 12 }}
                      />
                    </span>
                  </LinkButton>
                </span>
              </div>
              <div className="d-none d-lg-block">
                <PrintAndEmail emailLabel="Email" printLabel="Print" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tabs tabs={tabsData} />
      <MSPlanListHeader showInformationAdded={showInformationAdded} />
      <div className="container container-lg">
        <div className="row mb-20 mb-lg-30">
          <div className="column column-12">
            <UtilityBar
              isAuthenticated={isAuthenticated}
              savedDoctors={savedDoctors}
              savedDrugs={savedDrugs}
              savedPlans={savedPlans}
              onAddDoctorsClick={onAddDoctorsClick}
              onAddDrugsClick={onAddDrugsClick}
            />
          </div>
        </div>
        <div className="row">
          <div className="column column-3 d-none d-lg-block">
            <MSPlanListFilters />
          </div>
          <div className="column column-12 column-lg-9">
            <div className="d-flex d-lg-none align-items-center gap-20 mb-20">
              <Button isOutlined={true} isSecondary={true}>
                Filter Plans
              </Button>
              <LinkButton>Clear All</LinkButton>
            </div>
            {showAppliedFilters && (
              <div className="mb-20">
                <div className="d-flex align-items-center gap-15">
                  <div className="d-none d-lg-block text-bold">
                    Applied Filters:
                  </div>
                  <Tag isDismissable={true}>Vision discounts</Tag>
                  <LinkButton className="d-none d-lg-block">
                    Clear All
                  </LinkButton>
                </div>
              </div>
            )}
            <div className="d-flex align-items-center justify-content-between gap-20 mb-20">
              <div>
                Showing{' '}
                <span className="text-bold">
                  <Pluralize singular={'plan'} count={availablePlans.length} />
                </span>
              </div>
              <div className="d-flex align-items-center gap-20">
                <Menu
                  isBorderless={true}
                  items={[
                    'Lowest premium',
                    'Highest premium',
                    'Lowest out-of-pocket maximum',
                    'Highest out-of-pocket maximum'
                  ]}
                  label={
                    <div>
                      <span className="text-bold">Sort:</span> Lowest Premium
                    </div>
                  }
                  onItemSelect={() => {}}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-30">
              {availablePlans.map((plan, index) => (
                <Fragment key={index}>
                  <MSPlanCard
                    isExpanded={true}
                    isSaved={!!savedPlans.find((p) => p.id === plan.id)}
                    planData={plan}
                    showRestrictions={false}
                    onSave={onPlanSaveToggle}
                  />
                  <MSPlanListCTA index={index} />
                </Fragment>
              ))}
            </div>
            <div className="d-flex justify-content-center d-lg-none py-30">
              <PrintAndEmail emailLabel="Email" printLabel="Print" />
            </div>
          </div>
        </div>
      </div>
      <MSPlanListImportantInformation />
      <MSPlanListCallout />
      <MSHomeHeadline headline="Find answers to some of your Medicare Supplement questions." />
      <MSPlanListFAQs />
      <MSPlanListDisclaimers />
    </Fragment>
  );
};

export default MSPlanList;
