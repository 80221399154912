import classNames from 'classnames';
import UtilityTag from 'components/utility-tag/utility-tag';
import { Fragment } from 'react';
import Pluralize from 'react-pluralize';
import { Link } from 'react-router-dom';
import './utility-bar.scss';

const UtilityBar = ({
  className,
  isAuthenticated = false,
  savedDoctors = [],
  savedDrugs = [],
  savedPlans = [],
  onAddDoctorsClick = () => {},
  onAddDrugsClick = () => {}
}) => {
  const componentClassName = classNames('uhc-utility-bar', {}, className);

  return (
    <div className={componentClassName}>
      <div className="uhc-utility-bar__inner">
        <div className="uhc-utility-bar__left">
          <img
            className="uhc-utility-bar__icon"
            src="/assets/images/ic_Heart_2C_onBlue.png"
            alt="Heart"
          />
          <div className="uhc-utility-bar__content">
            <div className="uhc-utility-bar__title">
              <Link
                className="uhc-utility-bar__title-link"
                to="/account/dashboard"
              >
                <span>
                  {isAuthenticated ? 'Your account' : 'Your guest account'}
                </span>
                <img
                  className="uhc-utility-bar__title-icon"
                  src="/assets/icons/chevron-right-icon@2x.png"
                  alt=""
                />
              </Link>
            </div>
            <div className="uhc-utility-bar__subtitle">
              Keep track of your plans, drugs, doctors and more. Enter your information once and view personalized coverage options as your browse for plans.
            </div>
          </div>
        </div>
        <div className="uhc-utility-bar__right">
          <div className="uhc-utility-bar__tags">
            <UtilityTag as="button" onClick={onAddDoctorsClick}>
              {savedDoctors.length === 0 && (
                <Fragment>
                  <img
                    className="d-block mr-10"
                    src="/assets/icons/circle-add-icon@2x.png"
                    alt=""
                    style={{ height: 20, width: 20 }}
                  />
                  <span>
                    <span className="text-blue-secondary text-bold">
                      Add your doctors
                    </span>{' '}
                    to see if they are in network
                  </span>
                </Fragment>
              )}
              {savedDoctors.length > 0 && (
                <Fragment>
                  <img
                    className="d-block mr-10"
                    src="/assets/icons/check-circle@2x.png"
                    alt=""
                    style={{ height: 20, width: 20 }}
                  />
                  <span className="d-flex align-items-center justify-content-between flex-1">
                    <span className="text-bold">
                      <Pluralize
                        singular={'Doctor'}
                        count={savedDoctors.length}
                      />{' '}
                      added
                    </span>
                    <span className="text-blue-secondary text-bold mr-10">
                      Edit
                    </span>
                  </span>
                </Fragment>
              )}
            </UtilityTag>
            <UtilityTag as="button" onClick={onAddDrugsClick}>
              {savedDrugs.length === 0 && (
                <Fragment>
                  <img
                    className="d-block mr-10"
                    src="/assets/icons/circle-add-icon@2x.png"
                    alt=""
                    style={{ height: 20, width: 20 }}
                  />
                  <span>
                    <span className="text-blue-secondary text-bold">
                      Add your drugs
                    </span>{' '}
                    to see estimated drug costs
                  </span>
                </Fragment>
              )}
              {savedDrugs.length > 0 && (
                <Fragment>
                  <img
                    className="d-block mr-10"
                    src="/assets/icons/check-circle@2x.png"
                    alt=""
                    style={{ height: 20, width: 20 }}
                  />
                  <span className="d-flex align-items-center justify-content-between flex-1">
                    <span className="text-bold">
                      <Pluralize singular={'Drug'} count={savedDrugs.length} />{' '}
                      added
                    </span>
                    <span className="text-blue-secondary text-bold mr-10">
                      Edit
                    </span>
                  </span>
                </Fragment>
              )}
            </UtilityTag>
            <UtilityTag as={Link} to="/account/plans/saved">
              {savedPlans.length === 0 && (
                <Fragment>
                  <img
                    className="d-block mr-10"
                    src="/assets/icons/circle-add-icon@2x.png"
                    alt=""
                    style={{ height: 20, width: 20 }}
                  />
                  <span>
                    <span className="text-blue-secondary text-bold">
                      Add plans
                    </span>{' '}
                    to save and review anytime
                  </span>
                </Fragment>
              )}
              {savedPlans.length > 0 && (
                <Fragment>
                  <img
                    className="d-block mr-10"
                    src="/assets/icons/check-circle@2x.png"
                    alt=""
                    style={{ height: 20, width: 20 }}
                  />
                  <span className="d-flex align-items-center justify-content-between flex-1">
                    <span className="text-bold">
                      <Pluralize singular={'Plan'} count={savedPlans.length} />{' '}
                      saved
                    </span>
                    <span className="text-blue-secondary text-bold mr-10">
                      View
                    </span>
                  </span>
                </Fragment>
              )}
            </UtilityTag>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UtilityBar;
